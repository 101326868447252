// src/App.tsx
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TableViewLayout from '../../layouts/TableViewLayout';
import { useSearchParams } from 'react-router-dom';
import QRCodeGenerator from './../../components/QRCodeGenerator'
import Breadcrumb from '../../components/Breadcrumb';
import { fetchDashboardDatas,checkRoleUpdation, updateRoleUpdation } from '../../services/apiService';
import CryptoJS from 'crypto-js';
import { useAuth } from '../../hooks/AuthContext';
import Nav from '../../components/Nav';
import QRCodeWithLabel from '../../components/QRCodeWithLabel';
import BlackXoro from "../../assects/img/XORO-Black-1080 (5).png"

interface DashboardProps {
    setError: Dispatch<SetStateAction<string | null>>;
    setIsError: Dispatch<SetStateAction<boolean>>;
}

const Dashboard: React.FC<DashboardProps> = ({ setError, setIsError }) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') || '1';
    localStorage.setItem('currentDashboardPage',page)

    const { logout } = useAuth();
    async function checkRoleUpdationfun() { 
      return await checkRoleUpdation(localStorage.getItem('id')); 
    }
    useEffect(()=>{
      const checkRole = async () => { 
        const result = await checkRoleUpdationfun(); 
            if(result.role_update == 'true'){
                updateRoleUpdation(localStorage.getItem('id'))
                logout()
            }
        }; 
        checkRole();
    },[])

    const updatePageParam = (newPage: any) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };

    const handlePageChange = async (page: Number) => {
        updatePageParam(page);
        const result = await fetchDashboardDatas(page);
        setData(result);
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await fetchDashboardDatas(Number(page));
                setData(result);
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, [page, setError, setIsError]);

    const breadcrumbs = [
        { label: 'Dashboard', href: '' },
    ];

    if (loading) {
        return (
            <>
                <Breadcrumb breadcrumbs={breadcrumbs} />
                <div className='w-full h-[100vh] bg-white'>
                    <div role="status" className='bg-yellow-500 w-full h-full rounded-xl p-3 flex justify-center items-center'>
                        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    </div>
                </div>
            </>
        );
    }

    const encryptString = (string: any, secretKey: any) => {
        const encryptedString2 = CryptoJS.AES.encrypt(string, secretKey).toString();
        return encodeURIComponent(encryptedString2);
    };

    const desiredValues = data.dashboards ? data.dashboards.map((item: { id: any; account: { id: any; name: any; }; name: any; code: any; division: any; product_model: any; collect_mail:any; default_mail:any; created_by:any; updated_by:any}) => ({
        company: item.account.name,
        created_by: (item.created_by ? item.created_by[0].toUpperCase() : item.created_by)+'/'+(item.updated_by ? item.updated_by[0].toUpperCase() : item.updated_by),
        project: item.name,
        code: item.code,
        division: item.division,
        product_model: item.product_model,
        qr: (item.collect_mail == 'false') ? <QRCodeWithLabel brandingImage={BlackXoro} title={item.name} subtitle="AR Experience"  value={`https://app.xorostory.com/?code=${encryptString((item.code).toUpperCase(),'url')}&mail=${encryptString(item.default_mail,'mail')}`} /> :<QRCodeWithLabel brandingImage={BlackXoro} title={item.name} subtitle="AR Experience"  value={`https://app.xorostory.com/?code=${encryptString((item.code).toUpperCase(),'url')}`} /> ,
        url: (item.collect_mail == 'false') ?  `https://app.xorostory.com/?code=${encryptString((item.code).toUpperCase(),'url')}&mail=${encryptString(item.default_mail,'mail')}` :`https://app.xorostory.com/?code=${encryptString((item.code).toUpperCase(),'url')}`
    })) : {
        company: null,
        created_by: null,
        project: null,
        code: null,
        division: null,
        product_model: null,
        qr: null,
        url: null,
    };

    return (
        <div className='w-full h-full mt-1'>
            <Nav/>
            <Breadcrumb breadcrumbs={breadcrumbs}/>
            <div className='bg-yellow-500 w-full h-auto rounded-t-xl p-3'>
                <TableViewLayout
                    data = {{
                        clickable_value: data.dashboards,
                        clickable_link_type: "dashboard",
                        top: null,
                        headers: ["company","created by / last edited","story", "code", "division", "product_model", "qr", "url"],
                        values: desiredValues,
                        total_pages: data.total_pages,
                        current_page: data.current_page,
                        title: "DASHBOARD",
                        sub_title: "Story List",
                    }}
                    handlePageChange = {handlePageChange}
                />
            </div>
        </div>
    );
};

export default Dashboard;
