import React, { useState, useRef } from 'react';
import QRCode from 'qrcode.react';


interface QRCodeGeneratorProps {
    value: string;
  }

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({value}) => {
  const qrRef = useRef<HTMLDivElement>(null);

  return (
    <div className='flex justify-start items-center'>
      <div ref={qrRef} className='w-[20px] h-[20px] overflow-hidden'>
        <QRCode value={value} style={{ width: "20px", height: "20px" }}/>
      </div>
    </div>
  );
};

export default QRCodeGenerator;
