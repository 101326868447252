import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import TableViewLayout from '../../layouts/TableViewLayout';
import { useNavigate,useSearchParams } from 'react-router-dom';
import { fetchConfiguratorProjectsDatas,checkRoleUpdation, updateRoleUpdation , deleteProjects , updateProjectStatus1} from '../../services/apiService';
import { useAuth } from '../../hooks/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

interface DashboardProps {
  setError: Dispatch<SetStateAction<string | null>>;
  setIsError: Dispatch<SetStateAction<boolean>>;
}

const Configurator: React.FC<DashboardProps> = ({ setError, setIsError }) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDeleteID, setCurrentDeleteID] = useState();
  const [currentDeleteName, setCurrentDeleteName] = useState();
  const [deleteConformPopup,setDeleteConformPopup] = useState(false);
  const [projectStatusConformPopup,setProjectStatusConformPopup] = useState(false);
  const [currrentUpdateProjectStatus,setCurrrentUpdateProjectStatus] = useState('')
  const [currrentUpdateStatusProjectCode,setCurrrentUpdateStatusProjectCode] = useState('')
  const [currrentUpdateStatusProjectName,setCurrrentUpdateStatusProjectName] = useState('')
  const page = searchParams.get('page');
  const role = localStorage.getItem('role');
  const account_id = localStorage.getItem('account_id');
  const navigate = useNavigate();
  const { logout } = useAuth();
  async function checkRoleUpdationfun() { 
    return await checkRoleUpdation(localStorage.getItem('id')); 
  }
  useEffect(()=>{
    const checkRole = async () => { 
      const result = await checkRoleUpdationfun(); 
      if(result.role_update == 'true'){
          updateRoleUpdation(localStorage.getItem('id'))
          logout()
      }
    }; 
    checkRole();
    if(role === '3'|| role === '5'){
        navigate(`/dashboard?page=1`);
    }
  },[])

  const updatePageParam = (newPage: any) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const handlePageChange = async (page: Number) => {
    updatePageParam(page);
    const result = await fetchConfiguratorProjectsDatas(page);
    setData(result);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchConfiguratorProjectsDatas(Number(page));
        setData(result);
      } catch (error) {
        setIsError(true);
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [page, setError, setIsError]);

  const breadcrumbs = [
    { label: 'Configurator', href: '' },
  ];

  async function deleteProject(){
    try{
      const result1 = await deleteProjects(currentDeleteID)
      if(result1.delete == 'true'){
        const result = await fetchConfiguratorProjectsDatas(Number(page));
        setData(result);
      }
      else{
        alert('Error while deleting this project')
      }
      setDeleteConformPopup(false)
    }
    catch(err){
      console.log(err)
    }
  }

  function deleteProjectPopUp(id:any,project:any){
    setCurrentDeleteID(id)
    setCurrentDeleteName(project)
    setDeleteConformPopup(true)
  }

  let desiredValues;
  if(role === '1'){
    desiredValues = data != null ? data.projects.map((item: { name: any; code: any; stories: any; features: any; objects: any; company: any; id:any; active:any; created_by:any; updated_by:any}) => ({
      id: item.id,
      company: item.company,
      created_by: (item.created_by ? item.created_by[0].toUpperCase() : item.created_by)+'/'+(item.updated_by ? item.updated_by[0].toUpperCase() : item.updated_by),
      project: item.name,
      code: item.code,
      storylines: item.stories,
      features: item.features,
      active_status : item.active,
      deleteProj: item.id
      // objects: item.objects
    })) : [{
      company: null,
      created_by: null,
      project: null,
      code: null,
      storylines: null,
      features: null,
      // objects: null
    }];
  }
  else if(account_id === '1'){
    desiredValues = data != null ? data.projects.map((item: { name: any; code: any; stories: any; features: any; objects: any; company: any; id:any; active:any; created_by:any; updated_by:any}) => ({
      id: item.id,
      company: item.company,
      created_by: (item.created_by ? item.created_by[0].toUpperCase() : item.created_by)+'/'+(item.updated_by ? item.updated_by[0].toUpperCase() : item.updated_by),
      project: item.name,
      code: item.code,
      storylines: item.stories,
      features: item.features,
      active_status : item.active,
      // objects: item.objects
    })) : [{
      company: null,
      created_by: null,
      project: null,
      code: null,
      storylines: null,
      features: null,
      // objects: null
    }];
  }
  else{
    desiredValues = data != null ? data.projects.map((item: { name: any; code: any; stories: any; features: any; objects: any; company: any; id:any; created_by:any; updated_by:any}) => ({
      id: item.id,
      company: item.company,
      created_by: (item.created_by ? item.created_by[0].toUpperCase() : item.created_by)+'/'+(item.updated_by ? item.updated_by[0].toUpperCase() : item.updated_by),
      project: item.name,
      code: item.code,
      storylines: item.stories,
      features: item.features,
      // objects: item.objects
    })) : [{
      company: null,
      created_by: null,
      project: null,
      code: null,
      storylines: null,
      features: null,
      // objects: null
    }];
  }

  const UpdateProjectStatus = async () => {
    setLoading(true)
    try{
      await updateProjectStatus1(currrentUpdateProjectStatus,currrentUpdateStatusProjectCode)
      const result = await fetchConfiguratorProjectsDatas(Number(page));
      setData(result);
    }
    catch(err){
      console.log(err)
    }
    finally{
      setLoading(false)
      setProjectStatusConformPopup(false)
      setCurrrentUpdateProjectStatus('')
      setCurrrentUpdateStatusProjectCode('')
      setCurrrentUpdateStatusProjectName('')
    }
  }
  return (
    <div className='w-full h-full'>
      <div className='flex justify-between items-center pr-5'>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <div onClick={()=>localStorage.getItem('expire_status') == "true" && alert('Renew you licence to create projects')}><a href={localStorage.getItem('expire_status') == "false" ? '/configurator/form?page=project' : '#'} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Add new project</a></div>
      </div>
      <div className='bg-yellow-500 w-full h-auto rounded-t-xl p-3'>
        <TableViewLayout
          data = {{
            clickable_value: data && data.projects,
            clickable_link_type: "configurator",
            top: null,
            headers: role === '1' ? ["company","created by / last edited", "story", "code", "storylines", "features","active status","delete"] : account_id === '1' ? ["company", "story", "code", "storylines", "features","active status"] : ["company", "story", "code", "storylines", "features"],
            values: desiredValues,
            total_pages: data && data.total_pages,
            current_page: data && data.current_page,
            title: "Configurator",
            sub_title: "Story List"
          }}
          handlePageChange = {handlePageChange}
          deleteProject = {(row: any) => deleteProjectPopUp((role === '1') ? row.id : 0,(role === '1') ? row.project : '')}
          ProjectStatus = {(updateStatus: any, projectCode: any , projectName: any) => {
            setCurrrentUpdateProjectStatus(updateStatus)
            setCurrrentUpdateStatusProjectCode(projectCode)
            setCurrrentUpdateStatusProjectName(projectName)
            setProjectStatusConformPopup(true)
          }}
        />
      </div>
      {deleteConformPopup &&
        <div className='fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
          <div className='w-1/3 bg-white border-2 p-10 bg-white' style={{borderRadius:'25px'}}>
            <div className='flex items-center justify-center mb-5'>
              <FontAwesomeIcon icon={faCircleExclamation} className='bg-red-100 border border-red-500 p-2 text-red-500 text-2xl rounded-full'/>
            </div>
            <h3 className='text-center mb-2 font-bold'>Are you Sure?</h3>
            <p className='text-center text-gray-500'>This will delete the "{currentDeleteName}" permanently. You cannot undo this action.</p>
            <div className='flex justify-center items-center mt-2'>
              <button className='me-3 bg-red-400 text-white px-5 hover:bg-red-600 rounded' onClick={e=>{
                e.preventDefault()
                deleteProject()
              }}>Delete</button>
              <button className='bg-gray-500 text-white px-6 hover:bg-gray-600 rounded' onClick={e=>{
                e.preventDefault()
                setDeleteConformPopup(false)
              }}>Cancel</button>
            </div>
          </div>
        </div>
      }
      {
        projectStatusConformPopup &&
        <div className='fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
          <div className='w-1/3 bg-white border-2 p-10 bg-white' style={{borderRadius:'25px'}}>
            <div className='flex items-center justify-center mb-5'>
              <FontAwesomeIcon icon={faCircleExclamation} className='bg-red-100 border border-red-500 p-2 text-red-500 text-2xl rounded-full'/>
            </div>
            <h3 className='text-center mb-2 font-bold'>Are you Sure?</h3>
            <p className='text-center text-gray-500'>This will {currrentUpdateProjectStatus} the project "{currrentUpdateStatusProjectName}".</p>
            <div className='flex justify-center items-center mt-2'>
              <button className='me-3 bg-red-400 text-white px-5 hover:bg-red-600 rounded' onClick={e=>{
                e.preventDefault()
                UpdateProjectStatus()
              }}>Update</button>
              <button className='bg-gray-500 text-white px-6 hover:bg-gray-600 rounded' onClick={e=>{
                e.preventDefault()
                setProjectStatusConformPopup(false)
              }}>Cancel</button>
            </div>
          </div>
        </div>
      }
      {loading &&
          <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
              <div style={{background:'white',border:' 2px solid #0050C8'}} className="flex items-center rounded px-7 py-3">
                  <div className="spinner me-2"></div>
                  {
                      <p className="text-blue-700">Loading...</p>
                  }
              </div>
          </div>
      }
    </div>
  );
};

export default Configurator;