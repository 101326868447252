import React, { Dispatch, SetStateAction, useState } from "react";
import { useAuth } from "../../hooks/AuthContext";
import { fetchLogin, updateRoleUpdation, sendOtpApi , resetPassword} from "../../services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

interface LoginProps {
    setError: Dispatch<SetStateAction<string | null>>;
    setIsError: Dispatch<SetStateAction<boolean>>;
}

const Login: React.FC<LoginProps> = ({ setError, setIsError }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordRestPopup,setPasswordRestPopup] = useState(false)
    const [loading, setLoading] = useState<boolean>(false);
    const [emailToPassReset,setEmailToPassReset] = useState('')
    const [otp,setOtp] = useState('')
    const [userOtp,setUserOtp] = useState('')
    const [otpSuccess,setOtpSuccess] = useState(false)
    const [rePassword,setRePassword] = useState('')
    const [confirmRePassword,setConfirmRePassword] = useState('')

    const { login } = useAuth();

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            const result = await fetchLogin(String(email), String(password));
            console.log(result)
            if(result.message == 'Account subscription expired'){
                setIsError(true);
                setError('Account subscription expired');
            }
            else{
                localStorage.setItem('token', result.token);
                localStorage.setItem('account_id', result.account_id);
                localStorage.setItem('role', result.role);
                localStorage.setItem('id', result.id);
                localStorage.setItem('remaining_days', result.remainingDays);
                localStorage.setItem('expire_status', result.expireStatus);
                updateRoleUpdation(result.id)
                login();
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                if(error.message === 'Request failed with status code 401'){
                    setError('Email or password wrong');
                }
                else{
                    setError(error.message);
                }
                
            } else {
                setError("An unexpected error occurred");
            }
        }
    };

    const otpGenerate = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try{
            setLoading(true)
            const rr = await sendOtpApi(emailToPassReset)
            if(rr.data.message == 'user not found'){
                alert('User not found')
            }
            else{
                setOtp(rr.data.otp)
            } 
        }
        catch(err){
            setOtp('')
            console.log(err)
        }
        finally{
            setLoading(false)
        }
    }

    const PasswordRestPopupFun = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if(otp === userOtp){
            setOtpSuccess(true)
        }
        else{
            alert("Wrong OTP")
        }
    }

    const submitNewPassowrd = async(e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setLoading(false)
        try{
            if(rePassword === confirmRePassword){
                const result = await resetPassword(emailToPassReset,rePassword)
                if(result.reset == 'sucess'){
                    setPasswordRestPopup(false)
                    setOtpSuccess(false)
                    alert('Password Changed Sucessfully')
                }
                else{
                    alert(result.message)
                }
            }
            else{
                alert("Password and confirm password are not same")
            }
        }
        catch(err){
            console.log(err)
        }
        finally{
            setLoading(false)
        }
    }

    return (
        <section className="w-[60%]">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                            Sign in to your account
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="name@email.com"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="given-name"
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                                    Password
                                </label>
                                <input
                                    type={showPassword ? "text " : "password"}
                                    name="password"
                                    id="password"
                                    placeholder="••••••••"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete="family-name"
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            id="remember"
                                            aria-describedby="remember"
                                            type="checkbox"
                                            className="w-4 cursor-pointer h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                                            onChange={(e) => setShowPassword(e.target.checked)}
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="remember" className="text-gray-500">
                                            Show password
                                        </label>
                                    </div>
                                </div>
                                <button type="button" onClick={()=>setPasswordRestPopup(true)} className="text-sm font-medium text-blue-600 hover:underline">
                                    Forgot password?
                                </button>
                            </div>
                            <button
                                type="submit"
                                className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Sign in
                            </button>
                            {/* <p className="text-sm font-light text-gray-500">
                                Don’t have an account yet?{' '}
                                <a href="/signup" className="font-medium hover:underline text-blue-600">
                                    Sign up
                                </a>
                            </p> */}
                        </form>
                    </div>
                </div>
            </div>
            {
                passwordRestPopup &&
                    <div className='fixed z-50 top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
                        <div className='relative w-1/3 bg-white border-2 p-10 bg-white text-center' style={{borderRadius:'25px'}}>
                            <h3 className="text-2xl font-bold mb-4">Password Reset</h3>
                            {otp === '' &&  <form onSubmit={otpGenerate}>
                                                <input type="email" required placeholder="Enter Your Email" onChange={(e)=>setEmailToPassReset(e.target.value)} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`mb-3 mt-1 shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`}/> <br></br>
                                                <button type="submit" className="mt-2 bg-blue-500 text-white px-6 py-2 w-full hover:bg-blue-600 rounded">Generate OTP</button>
                                            </form>
                            }
                            {(!otpSuccess && otp !== '') &&  <form onSubmit={PasswordRestPopupFun}>
                                                <input type="text" required placeholder="Enter OTP" onChange={(e)=>setUserOtp(e.target.value)} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`mb-3 mt-1 shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`}/> <br></br>
                                                <button type="submit" className="mt-2 bg-blue-500 text-white px-6 py-2 w-full hover:bg-blue-600 rounded">Submit</button>
                                            </form>
                            }
                            {
                                otpSuccess &&   <div>
                                                    <p>Mail : {emailToPassReset}</p>
                                                    <form onSubmit={submitNewPassowrd}>
                                                        <input type="password" required placeholder="Enter Password" onChange={(e)=>setRePassword(e.target.value)} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`mb-3 mt-1 shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`}/> <br></br>
                                                        <input type="password" required placeholder="Confirm Password" onChange={(e)=>setConfirmRePassword(e.target.value)} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`mb-3 mt-1 shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`}/> <br></br>
                                                        <button type="submit" className="mt-2 bg-blue-500 text-white px-6 py-2 w-full hover:bg-blue-600 rounded">Submit</button>
                                                    </form>
                                                </div>
                            }
                            <div className="absolute top-2 right-4">
                                <button onClick={()=>{
                                    setPasswordRestPopup(false)
                                    setEmailToPassReset('')
                                    setUserOtp('')
                                    setRePassword('')
                                    setConfirmRePassword('')
                                }}><FontAwesomeIcon className="text-red-500 text-xl" icon={faXmarkCircle} /></button>
                            </div>
                        </div>
                    </div>
            }
            {loading &&
                <div className='fixed z-50 top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
                    <div style={{background:'white',border:' 2px solid #0050C8'}} className="flex items-center rounded px-7 py-3">
                        <div className="spinner me-2"></div>
                        {
                            <p className="text-blue-700">Loading...</p>
                        }
                    </div>
                </div>
            }
        </section>
    );
};

export default Login;