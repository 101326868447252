import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileAudio, faPlus, faTrash, faUpload, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'; 
import { fileUpload } from '../../services/apiService';
import '../../custom.css'

interface Field {
    name: string;
    label: string;
    placeholder?: string;
    disabled?: boolean;
    type?: string;
    value?: string | any;
    customField?: boolean;
    id?: any;
    limit?: any;
    default?: boolean;
}

interface FormProps {
    fields: Field[];
    handleChange: (event: any, field: any) => void;
    data: any;
    handleDeleteCustomField?: any;
    audioConvert?: any;
    selectedTextForConvertion?: string;
    onboarding_audio_url?: string;
    model_upload?:any;
    model_url?: string;
    image_upload?:any;
    image_url?: string;
    feature_audio?: string;
    story_audio?: string;
    storyCount?: string;
    featureCount?: string;
    tracking_mode_list?: string[];
    template_type_list?: string[];
    animation_type_list?: string[];
    storyline_type_list?: string[];
    feature_type_list?: string[];
    uiPlacement_type_list?: string[];
    threeDRepoOptions?: any;
    selectModelStatus?: any;
    selectModelStatusReturn?:(data: any) => void;
    audioUploaded?: any;
    imageUploaded?: any;
    modelUploaded?: any;
    androidAssestUploaded?: any;
    iosAssestUploaded?: any;
    webglAssestUploaded?: any;
    spatialAssestUploaded?: any;
    alreadyAudioUploaded?: any;
    alreadyImageUploaded?: any;
    alreadyModelUploaded?: any;
    audioUploadFailed?: any;
    audioPending?: any;
    assest_upload?: any;
    alreadyAndroidAssestUploaded?: any;
    alreadyIosAssestUploaded?: any;
    alreadyWebglAssestUploaded?: any;
    alreadySpatialAssestUploaded?: any;
    customModel?: any;
}

type RepoModel = { id: number; name: string; label: string; model_url: string; image_url: string; is_active: boolean; created_at: string; updated_at: string; };

const Form: React.FC<FormProps> = (props) => {
    const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedAndroidAssest, setSelectedAndroidAssest] = useState(null);
    const [selectedIosAssest, setSelectedIosAssest] = useState(null);
    const [selectedWebglAssest, setSelectedWebglAssest] = useState(null);
    const [selectedSpatialAssest, setSelectedSpatialAssest] = useState(null);
    const [storyOrFeatureCount,setStoryOrFeatureCount] = useState(0);
    // const [featureCount,setFeatureCount] = useState<string>('');
    const [tracking_mode_list,setTracking_mode_list] = useState<string[]>([])
    const [template_type_list,setTemplate_type_list] = useState<string[]>([])
    const [animation_type_list,setAnimation_type_list] = useState<string[]>([])
    const [storyline_type_list,setStoryline_type_list] = useState<string[]>([])
    const [feature_type_list,setFeature_type_list] = useState<string[]>([])
    const [uiPlacement_type_list,setUiPlacement_type_list] = useState<string[]>([])

    const [audioUploaded,setAudioUploaded] = useState(false);
    const [imageUploaded,setImageUploaded] = useState(false);
    const [modelUploaded,setModelUploaded] = useState(false);
    const [androidAssestUploaded,setAndroidAssestUploaded] = useState(false)
    const [iosAssestUploaded,setIosAssestUploaded] = useState(false)
    const [webglAssestUploaded,setWebglAssestUploaded] = useState(false)
    const [spatialAssestUploaded,setSpatialAssestUploaded] = useState(false)

    const [audioUploadFailed,setAudioUploadFailed] = useState(false);
    const [imageUploadFailed,setImageUploadFailed] = useState(false);
    const [modelUploadFailed,setModelUploadFailed] = useState(false);
    const [androidUploadFailed,setAndroidUploadFailed] = useState(false);
    const [iosUploadFailed,setIosUploadFailed] = useState(false);
    const [webglUploadFailed,setWebglUploadFailed] = useState(false);
    const [spatialUploadFailed,setSpatialUploadFailed] = useState(false);

    const [alreadyAudioUploaded,setAlreadyAudioUploaded] = useState(false);
    const [alreadyImageUploaded,setAlreadyImageUploaded] = useState(false);
    const [alreadyModelUploaded,setAlreadyModelUploaded] = useState(false);
    const [alreadyAndroidAssestUploaded,setAlreadyAndroidAssestUploaded] = useState(false);
    const [alreadyIosAssestUploaded,setAlreadyIosAssestUploaded] = useState(false);
    const [alreadyWebglAssestUploaded,setAlreadyWebglAssestUploaded] = useState(false);
    const [alreadySpatialAssestUploaded,setAlreadySpatialAssestUploaded] = useState(false);
    const [threeDRepoOptions,setThreeDRepoOptions] = useState([])
    const [threeDModelSelectPopup,setThreeDModelSelectPopup] = useState(false)
    const [selectedRepoModel,setSelectedRepoModel] = useState<RepoModel| null>(null)

    const [audioPending,setAudioPending] = useState(false)

    const [customModel,setCustomModel] = useState(false)

    const minimum_zoom = ['10%','20%','30%','40%','50%','60%','70%','80%','90%']
    const maximum_zoom = ['110%','120%','130%','140%','150%','160%','170%','180%','190%','200%']

    useEffect(()=>{
        setCustomModel(props.customModel)
    },[props.customModel])

    useEffect(()=>{
        setAudioPending(props.audioPending)
    },[props.audioPending])

    useEffect(()=>{
        if(props.threeDRepoOptions !== undefined){
        setThreeDRepoOptions(props.threeDRepoOptions)}
    },[props.threeDRepoOptions])

    useEffect(()=>{
        setAlreadyAudioUploaded(props.alreadyAudioUploaded)
    },[props.alreadyAudioUploaded])

    useEffect(()=>{
        setAlreadyImageUploaded(props.alreadyImageUploaded)
    },[props.alreadyImageUploaded])

    useEffect(()=>{
        setAlreadyModelUploaded(props.alreadyModelUploaded)
    },[props.alreadyModelUploaded])

    useEffect(()=>{
        setAlreadyAndroidAssestUploaded(props.alreadyAndroidAssestUploaded)
    },[props.alreadyAndroidAssestUploaded])

    useEffect(()=>{
        setAlreadyIosAssestUploaded(props.alreadyIosAssestUploaded)
    },[props.alreadyIosAssestUploaded])

    useEffect(()=>{
        setAlreadyWebglAssestUploaded(props.alreadyWebglAssestUploaded)
    },[props.alreadyWebglAssestUploaded])

    useEffect(()=>{
        setAlreadySpatialAssestUploaded(props.alreadySpatialAssestUploaded)
    },[props.alreadySpatialAssestUploaded])

    useEffect(()=>{
        setAudioUploaded(props.audioUploaded)
    },[props.audioUploaded])

    useEffect(()=>{
        setAudioUploadFailed(props.audioUploadFailed)
    },[props.audioUploadFailed])

    useEffect(()=>{
        setImageUploaded(props.imageUploaded)
    },[props.imageUploaded])

    useEffect(()=>{
        setModelUploaded(props.modelUploaded)
    },[props.modelUploaded])
    //
    useEffect(()=>{
        setAndroidAssestUploaded(props.androidAssestUploaded)
    },[props.androidAssestUploaded])

    useEffect(()=>{
        setIosAssestUploaded(props.iosAssestUploaded)
    },[props.iosAssestUploaded])

    useEffect(()=>{
        setWebglAssestUploaded(props.webglAssestUploaded)
    },[props.webglAssestUploaded])

    useEffect(()=>{
        setSpatialAssestUploaded(props.spatialAssestUploaded)
    },[props.spatialAssestUploaded])
    //

    useEffect(()=>{
        setTracking_mode_list(props.tracking_mode_list ?? [])
    }, [props.tracking_mode_list])

    useEffect(()=>{
        setUiPlacement_type_list(props.uiPlacement_type_list ?? [])
    }, [props.uiPlacement_type_list])

    useEffect(()=>{
        setTemplate_type_list(props.template_type_list ?? [])
    }, [props.template_type_list])

    useEffect(()=>{
        setAnimation_type_list(props.animation_type_list ?? [])
    }, [props.animation_type_list])

    useEffect(()=>{
        setStoryline_type_list(props.storyline_type_list ?? [])
    }, [props.storyline_type_list])

    useEffect(()=>{
        setFeature_type_list(props.feature_type_list ?? [])
    }, [props.feature_type_list])

    useEffect(() => {
        if(props.storyCount){
            setStoryOrFeatureCount(Number(props.storyCount))
        }
    }, [props.storyCount])
   
    useEffect(() => {
        if(props.featureCount){
            setStoryOrFeatureCount(Number(props.featureCount))
        }
    }, [props.featureCount])

    useEffect(() => {
        if (props.selectedTextForConvertion) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                Text_for_audio: props.selectedTextForConvertion
            }));
        }
    },[props.selectedTextForConvertion])

    useEffect(() => {
        props.data && setFormValues(
            props.fields.reduce((acc, field) => ({
                ...acc,
                [field.name]: props.data[field.name] ?? field.value ?? ""
            }), {})
        );
    }, [props.data])

    useEffect(() => {
        if (props.onboarding_audio_url) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                onboarding_audio_url: props.onboarding_audio_url
            }));
        }
    }, [props.onboarding_audio_url]);

    useEffect(() => {
        if (props.model_url) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                uploaded_model: props.model_url
            }));
        }
    }, [props.model_url]);

    useEffect(() => {
        if (props.image_url) {
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                branding_bg_url: props.image_url
            }));
        }
    }, [props.image_url]);

    useEffect(()=>{
        if(props.feature_audio){
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                audio_url: props.feature_audio
            }));
        }
    },[props.feature_audio])

    useEffect(()=>{
        if(props.story_audio){
            setFormValues(prevFormValues => ({
                ...prevFormValues,
                audio_url: props.story_audio
            }));
        }
    },[props.story_audio])

    useEffect(()=>{
        if(formValues['default_zoom'] === 'true' && (formValues['minimum_zoom'] !== '' || formValues['maximum_zoom'] !== '')){
            setFormValues({
                ...formValues,
                minimum_zoom : '',
                maximum_zoom : ''
            })
        }
        else if(formValues['default_zoom'] === ""){
            setFormValues({
                ...formValues,
                default_zoom : 'true',
            })
        }

        if(formValues['collect_mail'] === 'true' && formValues['default_mail'] !== ''){
            setFormValues({
                ...formValues,
                default_mail: ''
            })
        }
        else if(formValues['collect_mail'] == ""){
            setFormValues({
                ...formValues,
                collect_mail : 'true',
            })
        }

        if(formValues['enable_title'] === ''){
            setFormValues({
                ...formValues,
                enable_title: true
            })
        }

    },[formValues])

    useEffect(()=>{
        if(formValues['from_repository'] == true && formValues['repository_model'] !== null && formValues['repository_model'] !== ''){
            threeDRepoOptions.forEach((list:any) => {
                if(list.id.toString() === formValues['repository_model'].toString()){
                    setSelectedRepoModel(list)
                }
            })
        }
    },[formValues,threeDRepoOptions])

    const handleChange = (event: any, field: boolean) => {
        const { name, value , type , placeholder} = event.target;
        if(placeholder.toLowerCase() === 'Storyline Title'.toLowerCase()){
            if(/^[a-zA-Z\s]*$/.test(value)){
                setFormValues({
                    ...formValues,
                    [name]: type !== 'checkbox' ? value : (name === 'collect_mail' || name === 'default_zoom') ? (formValues[name] === 'true' ? 'false' : 'true') : formValues[name] === true ? false : true
                });
                if(name === 'Text_for_audio'){
                    setAudioUploadFailed(false)
                }
                if(type === 'checkbox'){
                    if(name === 'collect_mail' || name === 'default_zoom'){
                        event.target.value = (formValues[name] === 'true' ? 'false' : 'true')
                    }
                    else{
                        event.target.value = (formValues[name] === true ? false : true)
                    }
                }
                props.handleChange(event, field);
            }
            else{
                alert("No symbols allowed")
            }
        }
        else{
            setFormValues({
                ...formValues,
                [name]: type !== 'checkbox' ? value : (name === 'collect_mail' || name === 'default_zoom') ? (formValues[name] === 'true' ? 'false' : 'true') : formValues[name] === true ? false : true
            });
            if(name === 'Text_for_audio'){
                setAudioUploadFailed(false)
            }
            if(type === 'checkbox'){
                if(name === 'collect_mail' || name === 'default_zoom'){
                    event.target.value = (formValues[name] === 'true' ? 'false' : 'true')
                }
                else{
                    event.target.value = (formValues[name] === true ? false : true)
                }
            }
            props.handleChange(event, field);
        }
    };

    const audioConvert = (e:any) => {
        props.audioConvert(e,storyOrFeatureCount.toString());
    };

    const handleFileChange = (event:any) => { 
        setSelectedModel(event.target.files[0]);
        setModelUploaded(false) 
        setModelUploadFailed(false)
    }; 

    const handleImageChange = (event:any) => { 
        setSelectedImage(event.target.files[0]); 
        setImageUploaded(false)
        setImageUploadFailed(false)
    };

    const handleAssestChange = (event:any,field:string) => {
        if(field == 'android_app_assest'){
            setSelectedAndroidAssest(event.target.files[0]);
            setAndroidAssestUploaded(false) 
            setAndroidUploadFailed(false)
        }
        else if(field == 'ios_app_assest'){
            setSelectedIosAssest(event.target.files[0]);
            setIosAssestUploaded(false) 
            setIosUploadFailed(false)
        }
        else if(field == 'webgl_app_assest'){
            setSelectedWebglAssest(event.target.files[0]);
            setWebglAssestUploaded(false) 
            setWebglUploadFailed(false)
        }
        else if(field == 'spatial_app_assest'){
            setSelectedSpatialAssest(event.target.files[0]);
            setSpatialAssestUploaded(false) 
            setSpatialUploadFailed(false)
        }
    }

    const handleModelSubmit = async (event:any) => { 
        event.preventDefault(); 
        if (!selectedModel) {
            alert('No file selected!');
            return;
        }
        else{
            const formData = new FormData();
            formData.append('file', selectedModel);
            try { 
                const result = await props.model_upload(formData)
                if(result.status == 200){
                    setModelUploadFailed(false)
                }
                else{
                    setModelUploadFailed(true)
                }
            } 
            catch (error) { 
                console.error('Model upload error:', error); 
                alert('Model upload failed!'); 
                setModelUploadFailed(true)
            } 
        }
    };

    const handleImageSubmit = async (event:any) => { 
        event.preventDefault(); 
        if (!selectedImage) {
            alert('No image selected!');
            return;
        }
        else{
            const formData = new FormData();
            formData.append('file', selectedImage);
            try { 
                const result = await props.image_upload(formData)
                if(result.status == 200){
                    setImageUploadFailed(false)
                }
                else if(result.status != 200){
                    setImageUploadFailed(true)
                }
            } 
            catch (error) { 
                console.error('Image upload error:', error);  
                alert('Image uploaded failed!');
                setImageUploadFailed(true)
            } 
        }
    };

    const handleDeleteCustomField = (id:any, name:any) => {
        delete formValues[name];
        props.handleDeleteCustomField(id, name)
    }

    const assestUpload = async (event:any,field:any) => {
        event.preventDefault();
        if((field.placeholder == 'Upload Android' && selectedAndroidAssest == null) || (field.placeholder == 'Upload IOS' && selectedIosAssest == null) || (field.placeholder == 'Upload Webgl' && selectedWebglAssest == null) || (field.placeholder == 'Upload Spatial' && selectedSpatialAssest == null)){
            alert(`Select ${field.placeholder.slice(7)} Assest File`)
        }
        else{
            let linkLoc;
            const formData = new FormData();
            if(field.placeholder == 'Upload Android' && selectedAndroidAssest){
                formData.append('assest', selectedAndroidAssest);
                linkLoc = 'android_app_url';
            }
            else if(field.placeholder == 'Upload IOS' && selectedIosAssest){
                formData.append('assest', selectedIosAssest);
                linkLoc = 'ios_app_url';
            }
            else if(field.placeholder == 'Upload Webgl' && selectedWebglAssest){
                formData.append('assest', selectedWebglAssest);
                linkLoc = 'webgl_app_url';
            }
            else if(field.placeholder == 'Upload Spatial' && selectedSpatialAssest){
                formData.append('assest', selectedSpatialAssest);
                linkLoc = 'spatial_app_url';
            }

            try { 
                const result = await props.assest_upload(formData,linkLoc)
                if(result.status == 200){
                    if(field.placeholder == 'Upload Android'){
                        setAndroidUploadFailed(false)
                    }
                    else if(field.placeholder == 'Upload IOS'){
                        setIosUploadFailed(false)
                    }
                    else if(field.placeholder == 'Upload Webgl'){
                        setWebglUploadFailed(false)
                    }
                    else if(field.placeholder == 'Upload Spatial'){
                        setSpatialUploadFailed(false)
                    }
                }
                else if(result.status != 200){
                    if(field.placeholder == 'Upload Android'){
                        setAndroidUploadFailed(true)
                    }
                    else if(field.placeholder == 'Upload IOS'){
                        setIosUploadFailed(true)
                    }
                    else if(field.placeholder == 'Upload Webgl'){
                        setWebglUploadFailed(true)
                    }
                    else if(field.placeholder == 'Upload Spatial'){
                        setSpatialUploadFailed(true)
                    }
                }
            } 
            catch (error) { 
                console.error('Assest upload error:', error);  
                if(field.placeholder == 'Upload Android'){
                    setAndroidUploadFailed(true)
                }
                else if(field.placeholder == 'Upload IOS'){
                    setIosUploadFailed(true)
                }
                else if(field.placeholder == 'Upload Webgl'){
                    setWebglUploadFailed(true)
                }
                else if(field.placeholder == 'Upload Spatial'){
                    setSpatialUploadFailed(true)
                }
            } 
        }
    }

    return (
        <>
            {props.fields.map((field,index) => (
                field.type == 'lineBreak' ?
                    <div key={field.name+index} style={{flexBasis: '100%',height:'0'}}></div> 
                :  (((field.name !== 'minimum_zoom' && field.name !== 'maximum_zoom' && field.name !== 'default_mail' && field.name !== 'android_app_assest' && field.name !== 'ios_app_assest' && field.name !== 'webgl_app_assest' && field.name !== 'spatial_app_assest' && field.name !== "repository_model") || ((field.name === 'minimum_zoom' && formValues['default_zoom'] == 'false') || (field.name === "repository_model" && formValues['from_repository'] && !customModel) || (field.name === 'maximum_zoom' && formValues['default_zoom'] == 'false') || (field.name === 'default_mail' && formValues['collect_mail'] == 'false') || ((field.name === 'android_app_assest' || field.name === 'ios_app_assest' || field.name === 'webgl_app_assest' || field.name === 'spatial_app_assest') && formValues['custom_model'] == true))) &&
                    <div className={field.type === "button" ? `ms-5 w-1/5 flex items-start` :`mb-5 mx-5 w-1/5 relative`} key={field.name}>
                        {(!field.name.includes('button') && field.type !== "lineBreak") && <label htmlFor={field.name} style={{color:'#0050C8'}} className="mb-2 text-sm font-medium text-gray-900 flex justify-start items-center">
                                                        {/* {field.label?.split('_')
                                                            .map((word, i) =>
                                                                i === 0
                                                                    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                                    : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                            ).join(' ')}  */}
                                                            {field.label}
                                                            <span className='ml-2'>{field.customField && <button type="button" className="flex items-center" onClick={() => handleDeleteCustomField(field.id, field.name)} ><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>}</span>
                                                    </label>
                        }
                        {(field.type != "select" && field.type != "checkbox" && field.type !== "button" && field.type !== "lineBreak" && field.name !== "Text_for_audio") && <input
                            type={field.type ?? "text"}
                            name={field.name}
                            id={field.name}
                            maxLength={field.limit ? field.limit : ''}
                            style={{color:`black`,border:`${((field.name === "select_image" && imageUploadFailed) || (field.name === "select_model" && modelUploadFailed && !formValues['from_repository']) || (field.name === "android_app_assest" && androidUploadFailed) || (field.name === "ios_app_assest" && iosUploadFailed) || (field.name === "webgl_app_assest" && webglUploadFailed) || (field.name === "spatial_app_assest" && spatialUploadFailed)) ? '1.5px solid #FF0000' : ((field.name === "select_image" && (selectedImage !== null && !imageUploaded)) || (field.name === "select_model" && !formValues['from_repository'] && (selectedModel !== null && !modelUploaded)) || (field.name === "android_app_assest" && (selectedAndroidAssest !== null && !androidAssestUploaded)) || (field.name === "ios_app_assest" && (selectedIosAssest !== null && !iosAssestUploaded)) || (field.name === "webgl_app_assest" && (selectedWebglAssest !== null && !webglAssestUploaded)) || (field.name === "spatial_app_assest" && (selectedSpatialAssest !== null && !spatialAssestUploaded))) ? '1.5px solid #FFA500' : ((field.name === "select_image" && (imageUploaded || alreadyImageUploaded)) || (field.name === "select_model" && !formValues['from_repository'] && (modelUploaded || alreadyModelUploaded)) || (field.name === "android_app_assest" && (androidAssestUploaded || alreadyAndroidAssestUploaded)) || (field.name === "ios_app_assest" && (iosAssestUploaded || alreadyIosAssestUploaded)) || (field.name === "webgl_app_assest" && (webglAssestUploaded || alreadyWebglAssestUploaded)) || (field.name === "spatial_app_assest" && (spatialAssestUploaded || alreadySpatialAssestUploaded))) ? '1.5px solid #22c55e': '0.5px solid #94C1FF'}`,backgroundColor:'#F9FAFB',borderColor:`${Number(field.limit) === formValues[field.name]?.length && 'red'}`}}
                            className={`shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start ${(field.name == 'select_model' && (customModel || formValues['from_repository'])) ? 'opacity-50' : ''} ${field.limit == formValues[field.name]?.length && 'focus:outline-none focus:ring-2 focus:ring-red-500'}`}
                            placeholder={field.placeholder?.split('_')
                                .map((word, i) =>
                                    i === 0
                                        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                        : word.toLowerCase()
                                ).join(' ')}
                            disabled={(field.name == 'default_mail' && formValues['dont_collect_mail'] == 'false') || (field.name == 'select_model' && (customModel || formValues['from_repository'])) || (field.disabled ?? false)}
                            {...(field.type !== 'file' && { 
                                value: formValues[field.name] !== undefined ? formValues[field.name] : "" 
                            })}
                            onChange={(e) => {
                                if(field.name == 'select_model'){
                                    handleFileChange(e)
                                }
                                else if(field.name == 'select_image'){
                                    handleImageChange(e)
                                }
                                else if(field.name == 'android_app_assest' || field.name == 'ios_app_assest' || field.name == 'webgl_app_assest' || field.name == 'spatial_app_assest'){
                                    handleAssestChange(e,field.name)
                                }
                                else{
                                    handleChange(e, field.customField ?? false)
                                }

                                if(field.limit && e.target.value.length == Number(field.limit)){
                                    alert(`Only ${field.limit} character allowed for this field. Your last typed letter will be added.`)
                                }
                            }}
                        />}
                        {
                            ((field.name === "select_image" && imageUploaded) || (field.name === "select_model" && !customModel && !formValues['from_repository'] && modelUploaded) || (field.name === "android_app_assest" && androidAssestUploaded) || (field.name === "ios_app_assest" && iosAssestUploaded) || (field.name === "webgl_app_assest" && webglAssestUploaded) || (field.name === "spatial_app_assest" && spatialAssestUploaded)) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faCheck} className='text-sm text-white bg-green-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-green-600'>Upload Success</small> </div>
                        }
                        {
                            ((field.name === "select_image" && alreadyImageUploaded && !imageUploaded && (selectedImage === null)) || (field.name === "select_model" && !formValues['from_repository']  && !customModel && alreadyModelUploaded && !modelUploaded && (selectedModel === null)) || (field.name === "android_app_assest" && alreadyAndroidAssestUploaded && !androidAssestUploaded && (selectedAndroidAssest === null)) || (field.name === "ios_app_assest" && alreadyIosAssestUploaded && !iosAssestUploaded && (selectedIosAssest === null)) || (field.name === "webgl_app_assest" && alreadyWebglAssestUploaded && !webglAssestUploaded && (selectedWebglAssest === null)) || (field.name === "spatial_app_assest" && alreadySpatialAssestUploaded && !spatialAssestUploaded && (selectedSpatialAssest === null))) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faCheck} className='text-sm text-white bg-green-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-green-600'>Update if necessary</small> </div>
                        }

                        {
                            ((field.name === "select_image" && !alreadyImageUploaded && !imageUploaded && (selectedImage === null)) || (field.name === "select_model" && !formValues['from_repository']  && !customModel && !alreadyModelUploaded && !modelUploaded && (selectedModel === null)) || (field.name === "android_app_assest" && !alreadyAndroidAssestUploaded && !androidAssestUploaded && (selectedAndroidAssest === null)) || (field.name === "ios_app_assest" && !alreadyIosAssestUploaded && !iosAssestUploaded && (selectedIosAssest === null)) || (field.name === "webgl_app_assest" && !alreadyWebglAssestUploaded && !webglAssestUploaded && (selectedWebglAssest === null)) || (field.name === "spatial_app_assest" && !alreadySpatialAssestUploaded && !spatialAssestUploaded && (selectedSpatialAssest === null))) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faXmark} className='text-sm text-white bg-gray-400 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-gray-500'>Not yet uploaded</small> </div>
                        }
                        {
                            ((field.name === "select_image" && !imageUploaded && (selectedImage !== null) && !imageUploadFailed)) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faUpload} className='text-sm text-white bg-yellow-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-yellow-600'>Press Upload Image</small> </div>
                        }
                        {
                            ((field.name === "select_model" && !formValues['from_repository']  && !customModel && !modelUploaded && (selectedModel !== null) && !modelUploadFailed)) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faUpload} className='text-sm text-white bg-yellow-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-yellow-600'>Press Upload Model</small> </div>
                        }
                        {/* assests */}
                        {
                            ((field.name === "android_app_assest" && !androidAssestUploaded && (selectedAndroidAssest !== null) && !androidUploadFailed)) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faUpload} className='text-sm text-white bg-yellow-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-yellow-600'>Press Upload Android Assest</small> </div>
                        }
                        {
                            ((field.name === "ios_app_assest" && !iosAssestUploaded && (selectedIosAssest !== null) && !iosUploadFailed)) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faUpload} className='text-sm text-white bg-yellow-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-yellow-600'>Press Upload IOS Assest</small> </div>
                        }
                        {
                            ((field.name === "webgl_app_assest" && !webglAssestUploaded && (selectedWebglAssest !== null) && !webglUploadFailed)) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faUpload} className='text-sm text-white bg-yellow-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-yellow-600'>Press Upload Webgl Assest</small> </div>
                        }
                        {
                            ((field.name === "spatial_app_assest" && !spatialAssestUploaded && (selectedSpatialAssest !== null) && !spatialUploadFailed)) && 
                               <div className='flex items-center mt-1'><FontAwesomeIcon icon={faUpload} className='text-sm text-white bg-yellow-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-yellow-600'>Press Upload Spatial Assest</small> </div>
                        }
                        {/* assests */}
                        {
                            ((field.name === "select_image" && imageUploadFailed) || (field.name === "select_model" && !formValues['from_repository']   && !customModel&& modelUploadFailed) || (field.name === "android_app_assest" && androidUploadFailed) || (field.name === "ios_app_assest" && iosUploadFailed) || (field.name === "webgl_app_assest" && webglUploadFailed) || (field.name === "spatial_app_assest" && spatialUploadFailed)) &&
                                <div className='flex items-center mt-1'><FontAwesomeIcon icon={faXmark} className='text-sm text-white bg-red-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-red-600'>Upload Failed</small> </div>
                        }
                        {
                            (field.name == 'select_model' && customModel) &&
                            <div className='flex items-center mt-1'><FontAwesomeIcon icon={faXmark} className='text-sm text-white bg-red-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-red-600'>The model and animations are taken care by XORO Story</small> </div>
                        }
                        {
                            (field.name == 'select_model' && formValues['from_repository']  && !customModel) &&
                            <div className='flex items-center mt-1'><FontAwesomeIcon icon={faXmark} className='text-sm text-white bg-gray-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-gray-600'>Repository Model Taken</small> </div>
                        }
                            
                        {
                            field.name === "Text_for_audio" && 
                            <textarea 
                                name={field.name}
                                id={field.name}
                                maxLength={field.limit ? field.limit : ''}
                                {...(field.type !== 'file' && { 
                                    value: formValues[field.name] !== undefined ? formValues[field.name] : "" 
                                })}
                                placeholder={(field.name === "Text_for_audio" && alreadyAudioUploaded) ? 'Already uploaded,Update if necessary' : field.placeholder?.split('_')
                                    .map((word, i) =>
                                        i === 0
                                            ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                            : word.toLowerCase()
                                    ).join(' ')}
                                className='shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start h-[150px]'
                                style={{color:`black`,backgroundColor:'#F9FAFB',border:`${((field.name === "Text_for_audio" && audioUploadFailed && !audioPending)) ? '1.5px solid #FF0000' : ((field.name === "Text_for_audio" && ((audioUploaded && !audioPending) || (alreadyAudioUploaded && !audioPending)))) ? '1.5px solid #22c55e' : audioPending ? '1.5px solid #FFA500' : '0.5px solid #94C1FF'}`,whiteSpace:'pre-wrap',overflowWrap:"break-word",verticalAlign:'top'}}
                                onChange={(e) => {
                                    handleChange(e, field.customField ?? false)
                                    if(field.limit && e.target.value.length == Number(field.limit)){
                                        alert(`Only ${field.limit} character allowed for this field. Your last typed letter will be added.`)
                                    }
                                }}
                            >
                            </textarea>
                        }
                        {
                            (field.name === "Text_for_audio" && audioUploaded && !audioPending) &&
                                <div className='flex items-center mt-1 justify-between'>
                                    <div className='flex items-center'><FontAwesomeIcon icon={faCheck} className='text-sm text-white bg-green-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-green-600'>Upload Success</small></div>
                                    {(field.limit && formValues[field.name]) && <div className='flex items-center justify-end text-gray-500'><small>{formValues[field.name].length+'/'+field.limit}</small></div>}
                                </div>
                        }
                        {
                            (field.name === "Text_for_audio" && alreadyAudioUploaded && !audioUploaded && !audioPending) &&
                                <div className='flex items-center mt-1 justify-between'>
                                    <div className='flex items-center'><FontAwesomeIcon icon={faCheck} className='text-sm text-white bg-green-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-green-600'>Update if necessary</small> </div>
                                    {(field.limit && formValues[field.name]) && <div className='flex items-center justify-end text-gray-500'><small>{formValues[field.name].length+'/'+field.limit}</small></div>}
                                </div>
                        }
                        {
                            (field.name === "Text_for_audio" && !alreadyAudioUploaded && !audioUploaded && !audioPending) &&
                                <div className='flex items-center mt-1 justify-between'>
                                    <div className='flex items-center'><FontAwesomeIcon icon={faXmark} className='text-sm text-white bg-gray-400 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-gray-500'>Not yet uploaded</small> </div>
                                    {(field.limit && formValues[field.name]) && <div className='flex items-center justify-end text-gray-500'><small>{formValues[field.name].length+'/'+field.limit}</small></div>}
                                </div>
                        }
                        {
                            (field.name === "Text_for_audio" && audioUploadFailed && !audioPending) &&
                                <div className='flex items-center mt-1 justify-between'>
                                    <div className='flex items-center'><FontAwesomeIcon icon={faXmark} className='text-sm text-white bg-red-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-red-600'>Upload Failed</small> </div>
                                    {(field.limit && formValues[field.name]) && <div className='flex items-center justify-end text-gray-500'><small>{formValues[field.name].length+'/'+field.limit}</small></div>}
                                </div>
                        }
                        {
                            (field.name === "Text_for_audio" && audioPending) &&
                                <div className='flex items-center mt-1 justify-between'>
                                    <div className='flex items-center'><FontAwesomeIcon icon={faUpload} className='text-sm text-white bg-yellow-600 px-1 py-for-upload-icon me-2' style={{borderRadius:'50%'}}/> <small className='text-yellow-600'>Press Upload</small> </div>
                                    {(field.limit && formValues[field.name]) && <div className='flex items-center justify-end text-gray-500'><small>{formValues[field.name].length+'/'+field.limit}</small></div>}
                                </div>
                        }


                        {field.type == "checkbox" &&
                            <div className={`flex items-center rounded-lg w-full pt-2.5 pb-2.5 px-2 ${(field.name === 'from_repository' && customModel) ? 'opacity-50' : ''}`} style={{color:`black`,border:'0.5px solid #94C1FF',backgroundColor:'#F9FAFB'}}>
                                <small className='text-sm'>{field.placeholder}</small>
                                <input
                                    type={field.type}
                                    name={field.name}
                                    id={field.name}
                                    maxLength={field.limit ? field.limit : ''}
                                    className={`shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 text-start mt-0.5 ms-2`}
                                    placeholder={field.placeholder?.split('_')
                                        .map((word, i) =>
                                            i === 0
                                                ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                                : word.toLowerCase()
                                        ).join(' ')}
                                    disabled={(field.name === 'from_repository' && customModel) || (field.disabled ?? false)}
                                    checked={(field.name == 'default_zoom') ? (formValues[field.name] !== undefined && (formValues[field.name].toLowerCase() == 'true' ? false : true)) : field.name == 'collect_mail' ? (formValues[field.name] !== undefined && (formValues[field.name].toLowerCase() == 'true' ? true : false)) : formValues[field.name] !== undefined && (formValues[field.name])}
                                    onChange={(e) => {
                                        handleChange(e, field.customField ?? false)
                                    }}
                                />
                            </div>
                        }

                        {(field.type == "select" && field.name == "minimum_zoom") && 
                            <select disabled={formValues['default_zoom'] == 'false' ? false : true} name={field.name} style={{color:'#0050C8',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="">Select Mimimum Zoom</option>
                                {minimum_zoom.map((list: string, i: number) => (
                                    <option value={list} key={i}>{list}</option>
                                ))}
                            </select>
                        }
                        {(field.type == "select" && field.name == "maximum_zoom") && 
                            <select disabled={formValues['default_zoom'] == 'false' ? false : true} name={field.name} style={{color:'#0050C8',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="">Select Maximum Zoom</option>
                                {maximum_zoom.map((list: string, i: number) => (
                                    <option value={list} key={i}>{list}</option>
                                ))}
                            </select>
                        }

                        {field.type == "select" && (field.name == "account_name" || field.name == "story_name") &&
                            <select name={field.name} style={{color:'#0050C8',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                {
                                    field.value.map((story: any, i: any) => (
                                        <option value={story} key={i}>{story}</option>
                                    ))
                                }
                            </select>}

                        {field.type == "select" && field.name == "feature_name" &&
                            <select name={field.name} style={{color:'#0050C8',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                {field.value[formValues["story_name"]]?.map((feature: string, i: number) => (
                                    <option value={feature} key={i}>{feature}</option>
                                )) || <option value="">Select Story</option>}
                            </select>
                        }

                        {
                            (field.type == "select" && field.name == "repository_model" && formValues['from_repository'] && !customModel) &&
                                <div onClick={()=>setThreeDModelSelectPopup(pre=>!pre)} style={{color:'#0050C8',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} className='cursor-pointer rounded-lg w-full pt-2 pb-2 px-2 flex items-center h-[43px]'>
                                    {/* <select name={field.name} style={{color:'#0050C8',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                        <option value="">Select a model</option>
                                        {threeDRepoOptions?.map((model: any, i: number) => (
                                            <option value={model.name} key={i}>{model.name}</option>
                                        ))}
                                    </select> */}
                                    <img className='' src={(selectedRepoModel && selectedRepoModel.image_url) || ''} />
                                    <p className='text-black ps-2'>{!selectedRepoModel  ? 'Click here to select model' : (selectedRepoModel && selectedRepoModel.name)}</p>
                                </div>
                        }

                        {field.type == "select" && (field.name == "tracking_mode" || field.name == "template_type" || field.name == "animation_type" || field.name == "storyline_type" || field.name == "feature_type" || field.name == "ui_placement" ) &&
                            <select name={field.name} style={{color:'black',borderColor:'#94C1FF',backgroundColor:'#F9FAFB',border:'0.5px solid #94C1FF'}} value={formValues[field.name]} id={field.name} onChange={(e: any) => handleChange(e, field.customField ?? false)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="">Select a option</option>
                                {   field.name == "tracking_mode" ? tracking_mode_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption == 'Horizontal' ? 'Floor based tracking' : 'Wall based Tracking'}</option>
                                    ))
                                    :(field.name == "template_type")?
                                    template_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                    :(field.name == "animation_type")?
                                    animation_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i} disabled={tmOption === 'Custom Animations :'}>{tmOption}</option>
                                    ))
                                    :(field.name == "storyline_type")?
                                    storyline_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                    :(field.name == "feature_type")?
                                    feature_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                    :
                                    uiPlacement_type_list?.map((tmOption: string, i: number) => (
                                        <option value={tmOption} key={i}>{tmOption}</option>
                                    ))
                                }
                            </select>
                        }

                        {(field.type === "button" && field.label === "Convert to audio") &&
                            <button className="p-2.5 bg-blue-200 w-[300px] rounded mt-7" style={{backgroundColor:'#0F6FFF',color:'white'}} onClick={(e)=>{audioConvert(e)}} ><FontAwesomeIcon icon={faFileAudio} className='text-sm' /> {field.label}</button>
                        }

                        {(field.type === "button" && formValues['custom_model'] == true && (field.name === "android_button" || field.name === "ios_button" || field.name === "webgl_button" || field.name === "spatial_button")) &&
                            <button className="p-2.5 bg-blue-200 w-[300px] rounded mt-7" style={{backgroundColor:'#0F6FFF',color:'white'}} onClick={(e)=>{assestUpload(e,field)}} ><FontAwesomeIcon icon={faFileAudio} className='text-sm' /> {field.label}</button>
                        }

                        {(field.type === "button" && field.label === "Upload Model") &&
                            <button className={`p-2.5 ${(customModel || formValues['from_repository']) ? 'bg-gray-200 opacity-50' : 'bg-blue-200'} w-[300px] rounded mt-7`} disabled={customModel || formValues['from_repository']} style={{backgroundColor: (customModel || formValues['from_repository'])? 'gray':'#0F6FFF',color:'white'}} onClick={(e)=>{handleModelSubmit(e)}} ><FontAwesomeIcon icon={faUpload} className='text-sm' /> {field.label}</button>
                        }

                        {(field.type === "button" && field.label === "Upload Image") &&
                            <button className="p-2.5 bg-blue-200 w-[300px] rounded mt-7" style={{backgroundColor:'#0F6FFF',color:'white'}} onClick={(e)=>{handleImageSubmit(e)}} ><FontAwesomeIcon icon={faUpload} className='text-sm' /> {field.label}</button>
                        }
                        {
                           (field.limit && formValues[field.name] && field.name !== 'Text_for_audio') && <div className='flex items-center justify-end text-gray-500'><div className='rounded p-0.5'><small>{formValues[field.name].length+'/'+field.limit}</small></div></div>
                        }
                    </div>
                    )
            ))}
            {threeDModelSelectPopup &&
                <div className='fixed z-50 top-0 bottom-0 bg-gray-500 bg-opacity-50 left-0 right-0 flex justify-center items-center'>
                    <div className='relative w-1/3 bg-white border-2 p-10 bg-white flex' style={{borderRadius:'25px'}}>
                        {
                            threeDRepoOptions.map((list: any) => {
                                return  <div onClick={()=>{
                                    setThreeDModelSelectPopup(false)
                                    setSelectedRepoModel(list)
                                    handleChange({target : {name:'repository_model',value:list.id,type:'select'}},false)
                                }} className='border me-2 rounded w-[30%] h-[200px] hover:shadow-2xl cursor-pointer'>
                                            <div className='w-full h-[85%] overflow-hidden rounded'>
                                                <img className='w-full h-full' src={list.image_url}/>
                                            </div>
                                            <p className='text-center'>{list.name}</p>
                                        </div>
                            })
                        }
                        <div className="absolute top-2 right-4">
                            <button onClick={()=>{
                                setThreeDModelSelectPopup(false)
                            }}><FontAwesomeIcon className="text-red-500 text-xl" icon={faXmarkCircle} /></button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Form;