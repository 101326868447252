import { useEffect, useState } from "react";
import Form from "./Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createFeatures, deleteConfiguratorFeatureCustomField, fetchConfiguratorFeaturesData , audioUpload , audioConvert ,projectTableColumnFetch,audioEnhancer,fileUpload,deleteFeaturesData} from "../../services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faRemove, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomFieldCreateBtn from "../CustomFieldCreateBtn";
import '../../custom.css'
import axios from 'axios'

interface FeatureFormProps {
    setError: any;
    setIsError: any;
}

const FeatureForm: React.FC<FeatureFormProps> = ({ setError, setIsError }) => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [featureCount, setFeatureCount] = useState(0);
    const [storiesOption, setStoriesOption] = useState([]);
    const [featureData, setFeatureData] = useState<any>([]);
    const [audioUrl, setAudioUrl] = useState('');
    const [currentStory,setCurrentStory] = useState('');
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingMes,setLoadingMes]= useState('')
    const [animation_type_list,setAnimation_type_list] = useState([])
    const [feature_type_list,setFeature_type_list] = useState([])
    const [popupForAudioComparision,setPopupForAudioComparision] = useState(false)
    const [enhancedTextVersion,setEnhancedTextVersion] = useState('')
    const [alreadyAudioUploaded,setAlreadyAudioUploaded] = useState<Record<number, boolean>>({});
    const [alreadyImageUploaded,setAlreadyImageUploaded] = useState<Record<number, boolean>>({});
    const [audioUploaded,setAudioUploaded] = useState<Record<number, boolean>>({});
    const [imageUploaded,setImageUploaded] = useState<Record<number, boolean>>({});
    const [prevFeatureId,setPrevFeatureId] = useState<number[]>([]);
    const [audioUrlList,setAudioUrlList] = useState<Record<number, boolean>>({})
    const [needFeature,setNeedFeature] = useState(false)
    const account_id = localStorage.getItem('account_id')
    const role = localStorage.getItem('role');

    const navigate = useNavigate();

    const fields = [
        { name: 'story_name', label: 'Select Storyline', placeholder: 'Select Story', type: 'select', value: storiesOption },
        { name: 'name', label: 'Feature Title', placeholder: 'Feature Title', disable: true , limit:'20'},

        { name: 'animation_type', label: 'Select Animation Type', placeholder: 'Animation type',type:'select' },
        // { name: 'feature_type', label: 'Feature type', placeholder: 'Feature type' ,type:'select'},

        { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
        // { name: 'already_image_upload_status', label: 'Icon Image', placeholder: 'Already image upload status',type:'status'},
        { name: 'select_image', label: 'Upload Icon Image(Ratio-1:1)(Res:300-500)', placeholder: 'select image' , type:'file'},
        { name: 'img_button', label: 'Upload Image', placeholder: 'Upload Image',type:'button'},
        // { name: 'icon_url', label: 'Icon url', placeholder: 'Icon url' },
        // { name: 'image_upload_status', label: 'Image upload status', placeholder: 'Image upload status',type:'status'},

        // { name: 'object_url', label: 'Object url', placeholder: 'Object url', value: '', customField: false }

        { name: 'line_break', label: 'line break', placeholder: 'line break',type:'lineBreak'},
        // { name: 'already_audio_upload_status', label: 'Audio', placeholder: 'Already audio upload status',type:'status'},
        { name: 'Text_for_audio', label: 'Input Text For Voiceover', placeholder: 'Text for audio' , limit:'400'  },
        { name: 'audio_button', label: 'Convert to audio', placeholder: 'convert to audio',type:'button'},
        // { name: 'audio_url', label: 'Audio url', placeholder: 'Audio url',disabled:true },
        // { name: 'audio_upload_status', label: 'Audio upload status', placeholder: 'Audio upload status',type:'status'},
    ];
    const [fieldsCount, setFieldsCount] = useState<{ [key: string]: typeof fields }[]>([]);

    useEffect(() =>{
        async function colFetchFun1(){
            const res = await projectTableColumnFetch('animation_type_list')
            const values = res.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            const res3 = await projectTableColumnFetch('custom_animations_list')
            const values3 = res3.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            if(account_id === '1' && role === '7'){
                setAnimation_type_list(values.concat(['Custom Animations :']).concat(values3))
            }
            else{
                setAnimation_type_list(values)
            }

            const res2 = await projectTableColumnFetch('feature_type_list')
            const values2 = res2.data.column_data.filter((val:string)=> (val !== null && val !== ''))
            setFeature_type_list(values2)
        }
        colFetchFun1()
        
    },[])

    useEffect(()=>{
        for(let i=0;i<featureData.length;i++){
            if(!(i in audioUploaded)){
                setAudioUploaded(prevData => ({
                    ...prevData,
                    [i]:false
                }))
           }

            if(!(i in imageUploaded)){
                setImageUploaded(prevData => ({
                    ...prevData,
                    [i]:false
                }))
            }

            if(featureData[i]['icon_url'] && featureData[i]['icon_url'] != ''){
                setAlreadyImageUploaded(prevData => ({
                    ...prevData,
                    [i]:true
                }))
            }
            else{
                setAlreadyImageUploaded(prevData => ({
                    ...prevData,
                    [i]:false
                }))
            }

            if(featureData[i]['audio_url'] && featureData[i]['audio_url'] != ''){
                setAlreadyAudioUploaded(prevData => ({
                    ...prevData,
                    [i]:true
                }))
            }
            else{
                setAlreadyAudioUploaded(prevData => ({
                    ...prevData,
                    [i]:false
                }))
            }
        
        }
    },[featureData])

    useEffect(() => {
        setFeatureCount(featureData.length);
    }, [featureData]);

    const handleFeatureSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if(featureData.length !== 0){
            try {
                const result = await createFeatures(featureData, code);
                if (result.status == 200) {
                    // navigate(`/configurator/form?page=objects&code=${code}`)
                    navigate(`/configurator?page=1`)
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError('Error while submitting');
                } else {
                    setError("An unexpected error occurred while submitting");
                }
            } finally {
                setLoading(false);
            }
        }
        else{
            navigate(`/configurator?page=1`)
        }
    };

    useEffect(() => {
        const getFeatureData = async () => {
            try {
                const result = await fetchConfiguratorFeaturesData(String(code));
                if (result.status == 200) {
                    result.data.features.length !== 0 && setNeedFeature(true)
                    result.data.features.map((data: any) => {
                        setPrevFeatureId(prevData=>[
                            ...prevData,
                            data.id
                        ])
                    });
                    setFeatureData(result.data.features);
                    setStoriesOption(result.data.stories);
                }
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError('Error while getting data');
                } else {
                    setError("An unexpected error occurred while getting data");
                }
            } finally {
                setLoading(false);
            }
        };
        if (code != null) {
            getFeatureData();
        }
    }, [code, setError, setIsError]);

    useEffect(() => {
        if (featureCount === 0 && featureData.length === 0) {
            setFieldsCount([{ feature0: [...fields] }]);
        } else if (featureData.length > 0) {
            const initialFields = Array.from({ length: featureCount }, (_, index) => ({
                [`feature${index}`]: [...fields],
            }));

            initialFields[0] && featureData.forEach((data: any, index: any) => {
                const featureFields = initialFields[index][`feature${index}`] || [];
                const featureCustomFields = data.feature_custom_fields || {};
                const initialCustomFields = Object.values(featureCustomFields).map((customField: any) => ({
                    name: customField.attribute_value,
                    label: customField.attribute_value,
                    placeholder: customField.attribute_value,
                    value: customField.value,
                    customField: true,
                    type: customField.field_type,
                    id: customField.id
                }));

                if (featureFields) {
                    featureFields.push(...initialCustomFields);
                }
            });
            setFieldsCount(initialFields);
        } else if (fieldsCount.length < featureCount) {
            setFieldsCount(prev => {
                const newFields = Array.from({ length: featureCount - prev.length }, (_, index) => ({
                    [`feature${prev.length + index}`]: [...fields]
                }));
                return [...prev, ...newFields];
            });
        }

    }, [featureCount, featureData]);

    const audioConvert1 = async (e:any,storyCount:any) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMes('converting')
        try {
            // await   axios.post('https://api.xorostory.com/api/admin/audio/enhancer',{
            // await    axios.post('http://localhost:3000/api/admin/audio/enhancer',{
            //             text:featureData[Number(storyCount)]['Text_for_audio']
            //         })
            //         .then(async (response) =>{
            //             setEnhancedTextVersion(response.data.response.choices[0].message.content)
            //             setPopupForAudioComparision(true)
            //             setCurrentStory(storyCount)
            //         })
            //         .catch(error=>console.log(error))
            if(!featureData[Number(storyCount)]){
                alert('Enter feature name')
            }
            else if(!featureData[Number(storyCount)]['name']){
                alert('Enter feature name')
            }
            else if(featureData[Number(storyCount)]['name'] === '' || featureData[Number(storyCount)]['name'] === null){
                alert('Enter feature name')
            }
            else if(!featureData[Number(storyCount)]['Text_for_audio']){
                alert('Enter text for audio convertion')
            }
            else if(featureData[Number(storyCount)]['Text_for_audio'] == '' || featureData[Number(storyCount)]['Text_for_audio'] == null){
                alert('Enter text for audio convertion')
            }
            else if(featureData[Number(storyCount)] && featureData[Number(storyCount)]['Text_for_audio']){
                const result = await audioEnhancer(featureData[Number(storyCount)]['Text_for_audio'])
                if(result.data.status && result.data.status == 'success'){
                    setEnhancedTextVersion(result.data.response.choices[0].message.content)
                    setPopupForAudioComparision(true)
                    setCurrentStory(storyCount)
                }
            }
            else{
                alert('something went worng in audio enchancement')
            }
            
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError('error while audio enchancement');
            } else {
                setError("An unexpected error occurred while audio enchancement");
            }
        } finally {
            setLoading(false);
        }
    }

    const audioConvertionAfterComparision = async (e:any,selected:any) => {
        e.preventDefault();
        setPopupForAudioComparision(false)
        setLoading(true)
        setLoadingMes('converting')
        setAudioUrl('')
        try {
            let text;
            if(selected === 'enhancedOne'){
                setFeatureData((prevData:any) => { 
                    const newData = [...prevData]; 
                    newData[Number(currentStory)]['Text_for_audio'] = enhancedTextVersion; 
                    return newData; 
                })
                text = enhancedTextVersion
            }
            else{
                text = featureData[Number(currentStory)]['Text_for_audio']
            }
    
            const result = await audioConvert(text);
            const url = URL.createObjectURL(result.data);
            setAudioUrl(url)
            setAudioUrlList(prevData => {
                const updatedData: Record<number, boolean> = {}; 
                featureData.forEach((list:any,index:any) => {
                    if(index == Number(currentStory)){
                        updatedData[index] = true
                    }
                    else{
                        updatedData[index] = false
                    }
                })
                return updatedData;
            })
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError('error while audio convertion');
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    // const audioConvert1 = async (e:any,storyCount:any) => {
    //     e.preventDefault();
    //     setLoading(true)
    //     setLoadingMes('converting')
    //     try {
    //         const result = await audioConvert(featureData[storyCount]['Text_for_audio']);
    //         const url = URL.createObjectURL(result.data);
    //         setAudioUrl(url)
    //         setCurrentStory(storyCount)
    //     } catch (error) {
    //         setIsError(true);
    //         if (error instanceof Error) {
    //             setError(error.message);
    //         } else {
    //             setError("An unexpected error occurred");
    //         }
    //     } finally {
    //         setLoading(false);
    //     }
        
    // }

    const audioUpload1 = async (e:any) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMes('uploading')
        try {
            const result = await audioUpload(featureData[Number(currentStory)]['Text_for_audio'],featureData[Number(currentStory)]['name'],'feature',localStorage.getItem('project_name'));
            if(result.status == 200){
                setAudioUrlList(prevData => ({
                    ...prevData,
                    [Number(currentStory)] : false
                }))
                setAudioUploaded(prevData => ({
                    ...prevData,
                    [Number(currentStory)]:true
                }))
                setFeatureData((prevData:any) => {
                    const updatedProjectData = [...prevData];
                    if (updatedProjectData.length > 0) {
                        
                        updatedProjectData[Number(currentStory)].audio_url = result.data.s3_key;
                      } 
                      else {
                        updatedProjectData.push({ audio_url: result.data.s3_key });
                      }
                    return updatedProjectData;
                });
                setAudioUrl('')
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError('error while audio uploding process');
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    }

    const image_upload = (formData:any, featureCount:any) => {
        setLoading(true);
        setLoadingMes('uploading');
        return new Promise((resolve, reject) => {
            const file = formData.get('file');
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop();
            if (fileExtension === 'png' || fileExtension === 'jpeg') {
                const img = new Image();
                img.onload = async () => {
                    if (img.width >= 300 && img.width <= 500) {
                        if (img.width === img.height) {
                            const result = await fileUpload(formData, 'feature', localStorage.getItem('project_name'));
                            setImageUploaded(prevData => ({
                                ...prevData,
                                [featureCount]: true
                            }));
                            setFeatureData((prevData:any) => {
                                const updatedProjectData = [...prevData];
                                updatedProjectData[featureCount].icon_url = result.data.s3_key;
                                return updatedProjectData;
                            });
                            setLoading(false);
                            resolve(result);
                        } else {
                            alert('Same width and height (Ex: 300x300) are allowed for icon image');
                            setLoading(false);
                            resolve({ status: 2000 });
                        }
                    } else {
                        alert('Only "300 - 500" width allowed for icon image');
                        setLoading(false);
                        resolve({ status: 2000 });
                    }
                };
                img.onerror = () => {
                    alert('Error loading image');
                    setLoading(false);
                    reject(new Error('Error loading image'));
                };
                img.src = URL.createObjectURL(file);
            } else {
                alert('Only ".png" and ".jpeg" are allowed for branding image');
                setLoading(false);
                resolve({ status: 2000 });
            }
        });
    };
    

    const handleFeatureChange = (
        e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>),
        index: number,
        field: boolean
    ) => {
        const { name, value, type } = e.target;

        setFeatureData((prev: any) => {
            const updatedFeatures = [...prev];
            const feature = updatedFeatures[index] || {};
            feature[name] = value;
            if (field) {
                const featureCustomFields = feature.feature_custom_fields || {};
                const fieldIndex = Object.keys(featureCustomFields).findIndex(key => featureCustomFields[key].attribute_value === name);
                if (fieldIndex !== -1) {
                    featureCustomFields[fieldIndex] = {
                        ...featureCustomFields[fieldIndex],
                        value: value,
                    };
                } else {
                    featureCustomFields[Object.keys(featureCustomFields).length] = {
                        id: featureCustomFields[Object.keys(featureCustomFields).length]?.id,
                        attribute_value: name,
                        field_type: type,
                        value: value,
                    };
                }
                feature.feature_custom_fields = featureCustomFields;
            }
            updatedFeatures[index] = feature;
            return updatedFeatures;
        });
    };

    const handleFieldsDeletion = (featureKey: string) => {
        const featureIndex = fieldsCount.findIndex(item => featureKey in item);
        if (featureIndex === -1) {
            console.log('Story key not found');
            return;
        }
        setFeatureData((prev: any) => {
            const updatedFeatures = prev.filter((_: any, index: number) => index !== featureIndex);
            return updatedFeatures;
        });
        setFieldsCount((prev) => {
            const newFieldsCount = prev.filter((feature) => {
                return Object.keys(feature)[0] !== featureKey;
            });
            return newFieldsCount;
        });
        setFeatureCount((prev: any) => prev - 1);
    };

    const handleDeleteCustomField = (id: any, name: any, index: any) => {
        const fieldIndex = fieldsCount[index][`feature${index}`].findIndex((item: { name: any }) => item.name === name);
        if (fieldIndex !== -1 && fieldIndex) {
            setFeatureData((prev: any[]) => {
                const updatedFeatre = prev.map(feature => {
                    const featureCustomFields = [...(feature.feature_custom_fields || [])];
                    const fieldIndex = featureCustomFields.findIndex((item: { attribute_value: any }) => item.attribute_value === name);
                    if (fieldIndex !== -1) {
                        featureCustomFields.splice(fieldIndex, 1);
                    }
                    return {
                        ...feature,
                        feature_custom_fields: featureCustomFields
                    };
                });
                return updatedFeatre;
            });
            setFieldsCount((prev: any) => {
                const updatedFeatre = [...prev];
                const feature = updatedFeatre[index][`feature${index}`];
                feature.splice(fieldIndex, 1)
                return updatedFeatre;
            });
        }
        if (id !== undefined) {
            deleteConfiguratorFeatureCustomField(id);
        }
    }

    const handleFieldCreateSubmit = (input: any, index: any) => {
        setFeatureData((prev: any[]) => {
            const updatedFeature = [...prev];
            const feature = updatedFeature[index] || {};
            const featureCustomFields = feature.feature_custom_fields || {};
            featureCustomFields[Object.keys(featureCustomFields).length] = {
                id: featureCustomFields[Object.keys(featureCustomFields).length]?.id,
                attribute_value: input.name,
                field_type: input.type,
                value: '',
            };
            feature.feature_custom_fields = featureCustomFields;
            updatedFeature[index] = feature;
            return updatedFeature;
        });
    }

    const deleteFeature = async (e:any,indexx:any) => {
        e.preventDefault();
        setLoading(true)
        try{
            const result = await deleteFeaturesData(code,prevFeatureId[indexx])
            if(result.status == 200){
                // alert('Feature deleted successfully')
                setFeatureData((prevData:any) => 
                    prevData.filter((_:any, index:any) => index !== indexx
                ));
            }
        }
        finally{
            setLoading(false)
        }
    }

    const removeFeature = async (e:any,indexx:any) => {
        e.preventDefault();
        setFeatureData((prevData:any) => 
            prevData.filter((_:any, index:any) => index !== indexx
        ));
    }
    
    useEffect(()=>{
        if(featureData.length === 0){
            setNeedFeature(false)
        }
    },[featureData])
    
    return (
        <div>
            <div style={{position:'relative'}}>
                <form onSubmit={handleFeatureSubmit}>
                    <div className="px-10 flex justify-between items-center">
                        <div className="px-10 py-5 ps-0 text-lg flex items-center">
                                <p className='mr-4 text-xl me-2' style={{fontSize:'30px',color:'#0050C8'}}> Sub-Storylines </p>
                                {(!needFeature && prevFeatureId.length === 0) ?
                                    <FontAwesomeIcon icon={faPlus} className='text-sm bg-blue-600 text-white px-1.5 py-1 text-xl rounded-full' onClick={()=>setNeedFeature(true)}/>
                                :
                                (needFeature && prevFeatureId.length === 0) && <FontAwesomeIcon icon={faMinus} className='text-sm bg-blue-600 text-white px-1.5 py-1 text-xl rounded-full' onClick={()=>setNeedFeature(false)}/>
                                }
                        </div>
                        <button
                            type="submit"
                            style={{backgroundColor:'#058C7E'}}
                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 w-1/5 text-center"
                        >
                            Submit
                        </button>
                    </div>
                    <div className='h-[73vh] w-full overflow-y-auto '>
                        {(needFeature && Array.isArray(fieldsCount)) && fieldsCount.map((fieldsObj, index) => {
                            const featureKey = Object.keys(fieldsObj)[0];
                            return (
                                <div key={index}>
                                    <div className="px-10 py-5 text-lg flex items-center">
                                        <span className='mr-4 text-xl' style={{color:'#0050C8'}}>Sub-Storylines {index + 1}</span>
                                        {prevFeatureId.length !== 0 &&
                                            <button 
                                                style={{backgroundColor:'rgb(219, 3, 3)'}}
                                                className="text-white hover:bg-red-400 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-lg text-sm px-1.5 py-0.5 text-center font-bold"
                                                onClick={(e)=>deleteFeature(e,index)}
                                            ><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>
                                        }
                                        {
                                            prevFeatureId.length === 0 &&
                                            <button 
                                                style={{backgroundColor:'rgb(219, 3, 3)'}}
                                                className="text-white hover:bg-red-400 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-lg text-sm px-1.5 py-0.5 text-center font-bold"
                                                onClick={(e)=>removeFeature(e,index)}
                                            ><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>
                                        }
                                        {/* {index !== 0 && <button type="button" onClick={() => handleFieldsDeletion(featureKey)} className="flex items-center"><FontAwesomeIcon icon={faTrash} className='text-sm' /></button>} */}
                                    </div>
                                    <div className='w-full h-auto flex'>
                                        <div className='w-full px-5 flex flex-wrap'>
                                            <Form fields={fieldsObj[featureKey]} handleChange={(e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>), field) => handleFeatureChange(e, index, field)} data={featureData[index] ?? []} handleDeleteCustomField={(id: any, name: any) => handleDeleteCustomField(id, name, index)} selectedTextForConvertion={featureData[index]?.Text_for_audio} audioConvert={(e:any,storyCount:any)=>audioConvert1(e,storyCount)} feature_audio={featureData[index]?.audio_url} featureCount={(index).toString()} animation_type_list={animation_type_list} feature_type_list={feature_type_list} image_upload={(e:any)=>image_upload(e,index)} imageUploaded={imageUploaded[index]} audioUploaded={audioUploaded[index]} alreadyAudioUploaded={alreadyAudioUploaded[index]} alreadyImageUploaded={alreadyImageUploaded[index]} audioPending={audioUrlList[index]}/>
                                            <div style={{flexBasis: '100%',height:'0'}}></div>
                                            <div className="mb-5 mx-5 w-1/5 items-end justify-end flex">
                                                <CustomFieldCreateBtn index={index} keyName={`feature${index}`} setFieldsCount={setFieldsCount} handleFieldCreateSubmit={(inputValue: any) => handleFieldCreateSubmit(inputValue, index)} />
                                            </div>
                                            <div style={{flexBasis: '100%',height:'0'}}></div>
                                            {index === fieldsCount.length-1 &&
                                                <button
                                                    type='button'
                                                    onClick={() => setFeatureCount((prev: any) => prev + 1)}
                                                    className="text-blue-600 mx-5 mb-5 bg-blue-700 border border-blue-600 hover:bg-blue-800 hover:text-white bg-transparent rounded-lg px-3 py-1.5 text-center text-sm"
                                                >
                                                    <FontAwesomeIcon icon={faPlus} className='text-sm' />  Add feature
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </form>
                {audioUrl && 
                    ( 
                        <div className="flex items-center justify-center mt-3" style={{position:'absolute',top:'0',left: '50%',transform: 'translateX(-50%)'}}> 
                            <audio controls className="me-2"> 
                                <source src={audioUrl} type="audio/wav" /> Your browser does not support the audio element. 
                            </audio> 
                            <button className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm text-center p-2.5 font-bold" onClick={(e)=>{audioUpload1(e)}} >upload</button>
                        </div> 
                    )
                } 
            </div>
            {loading &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',border:' 2px solid #0050C8'}} className="flex items-center rounded px-7 py-3">
                        <div className="spinner me-2"></div>
                        {
                            loadingMes === 'converting' ?  (<p className="text-blue-700">Converting please wait...</p>)  : loadingMes === 'uploading' ? <p className="text-blue-700">Uploading please wait...</p> : <p className="text-blue-700">Loading please wait...</p> 
                        }
                    </div>
                </div>
            }
            {popupForAudioComparision &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',padding:'10px',width:'80%',height:'500px'}}>
                        <div className="w-full h-[20%] flex items-center justify-center">
                            <p className="text-center text-xl font-bold" style={{fontSize:'30px'}}>Please select one of the versions for audio conversion</p>
                        </div>
                        <div style={{background:'white',padding:'10px',width:'100%',display:'flex',alignItems:'start',justifyContent:'space-around',height:'80%'}}>
                            <div className="overflow-hidden w-[45%]  border h-full p-2">
                                <h4 className="text-center mb-4 underline">Previous One</h4>
                                <div className="overflow-y-auto h-[70%]">
                                    <p>{featureData[Number(currentStory)]['Text_for_audio']}</p>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button onClick={(e)=>(audioConvertionAfterComparision(e,'previousOne'))} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Your Text</button>
                                </div>
                            </div>
                            <div className="w-[1px] h-full bg-black"></div>
                            <div className="w-[45%] overflow-hidden overflow-y-auto border h-full p-2">
                                <h4 className="text-center mb-4 underline">Enhanced One</h4>
                                <div className="overflow-y-auto h-[70%]">
                                    <p>{enhancedTextVersion}</p>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <button onClick={(e)=>(audioConvertionAfterComparision(e,'enhancedOne'))} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Enhanced Text</button>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            }
        </div>
    );
};

export default FeatureForm;