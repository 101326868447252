import React, { useRef } from "react";
import QRCode from "qrcode";
import QRCodeGenerator from "./QRCodeGenerator";

const QRCodeWithLabel = ({ value, title, subtitle, brandingImage }) => {
  const canvasRef = useRef();

  const generateQRCode = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
  
    // Set dimensions
    const qrCodeSize = 160; // Height of the QR code
    const qrCodeWidth = 145; // Reduced width of the QR code
    const padding = 20; // Padding

    const textHeight = 100; // Space for title and subtitle

    const brandingHeight = 50; // Space for branding image
  
    // Get the device pixel ratio for better quality
    const scaleFactor = window.devicePixelRatio || 1; // 1 for standard resolution, higher for Retina displays
  
    // Set the canvas dimensions for better resolution
    canvas.width = (qrCodeSize + padding * 2) * scaleFactor;
    canvas.height = (textHeight + qrCodeSize + brandingHeight + padding * -1) * scaleFactor;
  
    // Scale the context to match the new canvas size
    ctx.scale(scaleFactor, scaleFactor);
  
    // Set white background for the entire canvas
    ctx.fillStyle = "#fff"; // White background
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with white
  
    // Add title and subtitle above the QR code
    ctx.font = "bold 18px Arial";
    ctx.textAlign = "center";
    ctx.fillStyle = "#000";
  
    // Title
    ctx.fillText(
      title,
      (canvas.width / scaleFactor) / 2, // Adjust for scaling
      (padding + 35) / scaleFactor // Adjust for scaling
    );
  
    // Subtitle
    ctx.font = "14px Arial";
    ctx.fillText(
      subtitle,
      (canvas.width / scaleFactor) / 2, // Adjust for scaling
      (padding + 65) / scaleFactor // Adjust for scaling
    );
  
    // Create an off-screen canvas for QR code generation
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = qrCodeSize; // Use full height for the QR code
    offscreenCanvas.height = qrCodeSize;
  
    // Generate the QR code
    await QRCode.toCanvas(offscreenCanvas, value, {
      width: qrCodeSize,
      margin: 0,
    });
  
    // Draw the QR code onto the main canvas with reduced width
    ctx.drawImage(
      offscreenCanvas,
      ((canvas.width / scaleFactor) - qrCodeWidth) / 2, // Center the reduced-width QR code
      (textHeight) / scaleFactor, // Adjust for scaling
      qrCodeWidth, // width
      qrCodeSize // Maintain original height
    );
  
    // Load and draw branding image below the QR code
    if (brandingImage) {
      const branding = new Image();
      branding.src = brandingImage;
      branding.onload = () => {
        const brandingWidth = 160; // Set desired branding image width
        const brandingX = ((canvas.width / scaleFactor) - brandingWidth) / 2; // Center the branding image
        const brandingY = (textHeight + qrCodeSize + 185) / scaleFactor; // Position below QR code
        ctx.drawImage(branding, brandingX, brandingY, brandingWidth, 40); // Adjust height as needed
      };
    }
  };  
  

  const downloadQRCode = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = `${title}_QRCode.png`;
    // Ensure the canvas size is set correctly before downloading
    link.href = canvas.toDataURL("image/png", 1.0); // Adjust the quality if necessary
    link.click();
  };

  React.useEffect(() => {
    generateQRCode();
  }, [value, title, subtitle, brandingImage]);

  return (
    <div onClick={downloadQRCode}>
      <QRCodeGenerator value={value} />
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
  </div>
  );
};

export default QRCodeWithLabel;
