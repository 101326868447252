import { Dispatch, SetStateAction, useEffect, useState } from "react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { useAuth } from '../../hooks/AuthContext';
import TableViewLayout from '../../layouts/TableViewLayout';
import SignUp from "../auth/SignUp"
import { fetchUsers, signUp, updateMemberRole, fetchUsersByAccount ,checkRoleUpdation, updateRoleUpdation, deleteUser,createAccount1,getSubscriptions1,resetPassword,submitAccountChangesApi,updateAccountStatusApi,sendOtpApi} from "../../services/apiService";
import Breadcrumb from '../../components/Breadcrumb';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash,faCircleExclamation, faBackward, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

interface RoleProps {
    setError: Dispatch<SetStateAction<string | null>>;
    setIsError: Dispatch<SetStateAction<boolean>>;
}

const Role: React.FC<RoleProps> = ({ setError, setIsError }) => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page');
    const [organization, setOrganization] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState(3);
    const [dataToSave,setDataToSave] = useState({})
    const [accountToView,setAccountToView] = useState('')
    const [accountUsersToShow,setAccountUsersToShow] = useState([])
    const [currentAccountId,setCurrentAccountId] = useState('')
    const [toggleCreateAccount,setToggleCreateAccount] = useState(false)
    const [accountName, setAccountName] = useState('');
    const [accountEmail, setAccountEmail] = useState('');
    const [accountContactPerson, setAccountContactPerson] = useState('');
    const [accountPhone, setAccountPhone] = useState('');
    const [subs,setSubs] = useState([])
    const [selectedSub,setSelectedSub] = useState(0)
    const [accountAmount,setAccountAmount] = useState(0)
    const [currentDeleteID, setCurrentDeleteID] = useState();
    const [currentDeleteName, setCurrentDeleteName] = useState();
    const [deleteConformPopup,setDeleteConformPopup] = useState(false);
    const [resetPasswordPopup,setResetPasswordPopup] = useState(false)
    const [restPasswordEmail,setRestPasswordEmail] = useState('')
    const [restPasswordUser,setRestPasswordUser] = useState('')
    const [newPassword,setNewPassword] = useState('')
    const [newConfirmPassword,setNewConfirmPassword] = useState('')
    const [currectAccountEditRow,setCurrectAccountEditRow] = useState<any>({})
    const [toggleAccountEditPopup,setToggleAccountEditPopup] = useState(false)
    const [accountStatusPopup,setAccountStatusPopup] = useState(false)
    const [currentAccountStatusID,setCurrentAccountStatusID] = useState('')
    const [updatedAccountStatus,setUpdatedAccountStatus] = useState('')
    const [currectAccountName,setCurrectAccountName] = useState('')
    const [cRole,setCRole] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const { logout } = useAuth();

    async function checkRoleUpdationfun() { 
        return await checkRoleUpdation(localStorage.getItem('id')); 
    }
    useEffect(()=>{
        const CurrentRole = localStorage.getItem('role'); 
        if (CurrentRole === '3' || CurrentRole === '6'|| CurrentRole === '7'|| CurrentRole === '8') { 
            navigate(`/dashboard?page=1`); 
        } 
        else{
            setCRole(CurrentRole || '')
        }
        getSubscriptions()
    },[])

    const getSubscriptions = async () => {
        const res = await getSubscriptions1()
        setSubs(res)
    }

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    // const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (id:any,roleId:any) => {
        setDataToSave(prevData => ({
            ...prevData,
            [id]:Number(roleId)
        }));
    };

    const handleChangeRole = (event: any) => {
        setRole(event);
    };

    const getData = async () => {
        try {
            const result = await fetchUsers(Number(page));
            setData(result);
            setCurrentAccountId(result.account_id)
            setOrganization(result.organization);
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [page, setError, setIsError]);

    const updatePageParam = (newPage: any) => {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
    };
    const handlePageChange = async (page: Number) => {
        updatePageParam(page);
        const result = await fetchUsers(page);
        setData(result);
    };
    const handleSave = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            if(Object.keys(dataToSave).length !== 0){
                const result = await updateMemberRole(dataToSave);
                if (result.status == 200) {
                    window.location.reload();
                }
            }
            else{
                window.location.reload();
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        }
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            const result = await signUp(String(currentAccountId), String(name), String(email), String(phone), String(password), String(confirmPassword), Number(role));
            if (result.status == 201) {
                setIsOpen(false);
                showUsersByAccount(currentAccountId,accountToView)
                // window.location.reload();
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        }
    };
  
    const showUsersByAccount = async (id:any,name:string) => {
        const result = await fetchUsersByAccount(id)
        setCurrentAccountId(id)
        setOrganization(name)
        setAccountToView(name)
        setAccountUsersToShow(result.users)
    }

    function calculateDaysBetween(currentDate:any, targetDate:any) {
        const current: Date = new Date(currentDate);
        const target: Date = new Date(targetDate);

        const currentTime: number = current.getTime();
        const targetTime: number = target.getTime();

        const differenceInMilliseconds: number = targetTime - currentTime;
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        return differenceInDays;
    }
      

    const accountsData = data?.accounts?.map((item: { name: string; contact_person: string; is_active: boolean; id: any; subscription:any; projectCount:any}) => ({
        id: item.id,
        name: item.name,
        contact_person: item.contact_person,
        accountStatus: item.is_active ? 'Active' : 'Inactive',
        projects: item.projectCount,
        remainingDays: calculateDaysBetween(new Date().toISOString().split('T')[0], item.subscription.next_renewal_at),
        expiry: item.subscription.next_renewal_at,
        AccountEdit: item.id
    }));


    const breadcrumbs = [
        { label: 'Account Management', href: '' },
    ];

    function deleteUserPopUp(id:any,name:any){
        setCurrentDeleteID(id)
        setCurrentDeleteName(name)
        setDeleteConformPopup(true)
    }

    const deleteUser1 = async () => {
        const result = await deleteUser(currentDeleteID);
        if (result.deleted == 'true') {
            showUsersByAccount(currentAccountId,accountToView)
        }
        setDeleteConformPopup(false)
    }

    const createAccount = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            if(accountName == '' || accountEmail == '' || accountContactPerson == '' || accountPhone == '' || selectedSub == 0){
                alert('Enter all details for account creation')
            }
            else{
                const result = await createAccount1(accountName,accountContactPerson,accountEmail,accountPhone,selectedSub,accountAmount)
                if(result?.data?.message == 'Account and subscription created successfully'){
                    setToggleCreateAccount(false)
                    setAccountContactPerson('')
                    setAccountEmail('')
                    setAccountName('')
                    setAccountPhone('')
                    setSelectedSub(0)
                    setAccountAmount(0)
                    const getDataa = async () => {
                        try {
                            const result = await fetchUsers(Number(page));
                            setData(result);
                            setOrganization(result.organization);
                        } catch (error) {
                            setIsError(true);
                            if (error instanceof Error) {
                                setError(error.message);
                            } else {
                                setError("An unexpected error occurred");
                            }
                        } finally {
                            setLoading(false);
                        }
                    };
                    getDataa();
                }
            }
        } catch (error) {
            setIsError(true);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unexpected error occurred");
            }
        }
    }

    const submitNewPassword = async () => {
        if(newPassword == ''){
            alert('Enter password to submit')
        }
        else if(newConfirmPassword == ''){
            alert('Enter confirm password')
        }
        else if(newPassword !== newConfirmPassword){
            alert('Password and confirm password are not same')
        }
        else{
            const result = await resetPassword(restPasswordEmail,newPassword)
            if(result.reset == 'sucess'){
                alert('Password Changed Sucessfully')
                setResetPasswordPopup(false)
                setRestPasswordEmail('')
                setRestPasswordUser('')
                setNewPassword('')
                setNewConfirmPassword('')
            }
            else{
                alert(result.message)
            }
        }
    }

    const accountEditPopup = (row:any) => {
        setToggleAccountEditPopup((cv) => !cv)
        setCurrectAccountEditRow(row)
    }

    const submitAccountChanges = async() => {
        setLoading(true)
        try{
            const obj = {
                id : Number(currectAccountEditRow.id),
                accountName : currectAccountEditRow.name,
                contactPerson : currectAccountEditRow.contact_person,
                expiry : currectAccountEditRow.expiry,
            }
            const result = await submitAccountChangesApi(obj)
            if(result.status == 200){
                getData();
                setLoading(true)
                getSubscriptions()
                setLoading(true)
            }
        }
        catch(err){
            console.log(err)
        }
        finally{
            setToggleAccountEditPopup(false)
            setCurrectAccountEditRow({})
            setLoading(false)
        }
    }

    const UpdateAccountStatus = async () => {
        setLoading(true)
        try{
            setAccountStatusPopup(false)
            const result = await updateAccountStatusApi(Number(currentAccountStatusID),updatedAccountStatus)
            if(result.status == 200){
                getData();
                setLoading(true)
                getSubscriptions()
                setLoading(true)
            }
        }
        catch(err){
            console.log(err)
        }
        finally{
            setAccountStatusPopup(false)
            setLoading(false)
        }
    }

    return (
        <div className="w-full">
            <div className="flex items-center justify-between">
                <Breadcrumb breadcrumbs={breadcrumbs} />
                {cRole == '1' && <button className="me-3 bg-blue-600 text-white rounded px-1.5 py-1 font-medium" onClick={()=>setToggleCreateAccount(true)}>Create Account</button>}
            </div>
            <div className={`w-full h-auto ${(data && data.role !== "super_admin") ? 'p-5' : ''}`}>
                <div className="flex mt-1 px-5">
                    {data && data.role !== "super_admin" && <div className="w-full flex justify-start items-center">
                        <h1 className="text-3xl font-bold">Members</h1>
                    </div>}
                    {data && data.role !== "super_admin" && <div className={`w-full flex justify-end items-center ${data.role !== "super_admin" && data.role !== "admin" && data.role !== "account_admin" && data.role !== "account_manager" ? "hidden" : "block"}`}>
                        <button type="button" onClick={()=> localStorage.getItem('expire_status') == "false" ? toggleModal : alert('Renew you licence to add members')} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">+ Add members</button>
                    </div>}
                </div>
                <div className={`flex w-full h-full ${(data && data.role !== "super_admin") ? 'mt-6' : ''}`}>
                    <div className={`${(data && data.role !== "super_admin") ? 'w-2/3 p-5' : 'w-full'} h-full`}>
                        <div className="flow-root">
                        {(data && data.role !== "super_admin") ?
                            <ul role="list">
                                {data && data.users.map((user: any, index: number) => (
                                    <li key={index} className="pb-5">
                                        <div className="flex items-center space-x-4">
                                            <div className={`flex-shrink-0 flex justify-center items-center text-white w-10 h-10 rounded-lg bg-indigo-500`}>
                                                {user.name.charAt(0).toUpperCase()}
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <p className="text-sm font-medium text-gray-900 truncate">
                                                    {user.name}
                                                </p>
                                                <p className="text-sm text-gray-500 truncate">
                                                    {user.email}
                                                </p>
                                            </div>
                                            <div>
                                                {(data && data.role === "account_admin" && (user.role.name === "super_admin" || user.role.name === "account_admin"  || user.role.name === "modellor" || user.role.name === "marketing")) && 
                                                <select   
                                                    disabled                                                      
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    <option>{user.role.label}</option>
                                                </select>}
                                                {(data && data.role === "account_admin" && user.role.name !== "super_admin" && user.role.name !== "account_admin"  && user.role.name !== "modellor" && user.role.name !== "marketing") && 
                                                    <select
                                                        id="countries"
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        onChange={e => handleChange(user.id,e.target.value)}
                                                    >
                                                        <option value={user.role.id}>{user.role.label}</option>
                                                        {data.roles && data.roles.map((role: any, index: any) => (
                                                            role.name !== user.role.name ? (
                                                                <option key={index} value={role.id}>{role.label}</option>
                                                            ) : null
                                                        ))}
                                                    </select>
                                                }
                                                {(data && data.role === "admin" && (user.role.name === "super_admin" || user.role.name === "account_admin" || user.role.name === "admin"  || user.role.name === "modellor" || user.role.name === "marketing")) &&
                                                <select   
                                                    disabled                                                      
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    <option>{user.role.label}</option>
                                                </select>}
                                                {(data && data.role === "admin" && user.role.name !== "super_admin" && user.role.name !== "account_admin" &&  user.role.name !== "admin"  && user.role.name !== "modellor" && user.role.name !== "marketing") && 
                                                    <select
                                                        id="countries"
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        onChange={e => handleChange(user.id,e.target.value)}
                                                    >
                                                        <option value={user.role.id}>{user.role.label}</option>
                                                        {data.roles && data.roles.map((role: any, index: any) => (
                                                            role.name !== user.role.name ? (
                                                                <option key={index} value={role.id}>{role.label}</option>
                                                            ) : null
                                                        ))}
                                                    </select>
                                                }
                                                {(data && data.role === "account_manager" && (user.role.name === "super_admin" || user.role.name === "account_admin" || user.role.name === "admin" || user.role.name === "account_manager"  || user.role.name === "modellor" || user.role.name === "marketing")) && 
                                                <select   
                                                    disabled                                                      
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    <option>{user.role.label}</option>
                                                </select>}
                                                {(data && data.role === "account_manager" && user.role.name !== "super_admin" && user.role.name !== "account_admin" &&  user.role.name !== "admin" && user.role.name !== "account_manager"  && user.role.name !== "modellor" && user.role.name !== "marketing") && 
                                                    <select
                                                        id="countries"
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        onChange={e => handleChange(user.id,e.target.value)}
                                                    >
                                                        <option value={user.role.id}>{user.role.label}</option>
                                                        {data.roles && data.roles.map((role: any, index: any) => (
                                                            role.name !== user.role.name ? (
                                                                <option key={index} value={role.id}>{role.label}</option>
                                                            ) : null
                                                        ))}
                                                    </select>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                ))} 
                            </ul>
                        :
                            <div>
                                <div className="bg-yellow-500 w-full h-auto rounded-t-xl p-3">
                                    <TableViewLayout
                                        data = {{
                                            clickable_value: data?.accounts,
                                            clickable_link_type: "account",
                                            top: null,
                                            headers: ["Name", "Contact Person", "Status","Projects","Remaining Days","Expiry Date","Edit"],
                                            values: accountsData,
                                            total_pages: data?.total_pages,
                                            current_page: data?.current_page,
                                            title: "Accounts",
                                            sub_title: "Accounts List",
                                        }}
                                        handlePageChange = {handlePageChange}
                                        onRowClick = {(row: any) => showUsersByAccount(row.id, row.name)}
                                        AccountEdit = {(row: any) => (accountEditPopup(row))}
                                        accountStatusUpdate = {(id: string , status: string ,name : string) => {
                                            setAccountStatusPopup(true)
                                            setCurrentAccountStatusID(id)
                                            setUpdatedAccountStatus(status)
                                            setCurrectAccountName(name)
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            {data && data.role !== "super_admin" && <div className={`w-2/3 mt-3 ${data.total_pages <= 1 ? 'hidden' : 'flex'}`}>
                                {Pagination({ data, handlePageChange })}
                            </div>}
                            {data && data.role !== "super_admin" && <button type="button" onClick={e=>handleSave(e)} className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${data.role !== "super_admin" && data.role !== "admin" && data.role !== "account_admin" && data.role !== "account_manager" ? "hidden" : "block"}`}>Save</button>}
                        </div>
                    </div>
                    {data && data.role !== "super_admin" &&
                        <div className="w-1/3 h-full py-5 px-10 text-gray-500 font-normal text-sm">
                            <p>A member access page restricts specific content or services to registered users only. Visitors must log in with their credentials to gain access. This ensures that sensitive or premium information is protected and only available to authorized users.</p>
                            <p className="pt-5">It enhances security, provides personalized user experiences, and can offer exclusive benefits or content, encouraging user registration and engagement on the platform.</p>
                        </div>  
                    }
                </div>

            </div>
            <div>
                {isOpen && (
                    <div
                        id="authentication-modal"
                        aria-hidden="true"
                        className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-y-auto bg-black bg-opacity-50"
                    >
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow">
                                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Add member
                                    </h3>
                                    <button
                                        type="button"
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                                        onClick={toggleModal}
                                    >
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                {/* Modal body */}
                                <div className="p-4 md:p-5">
                                    <form className="space-y-4" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="Name"
                                                required
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="name@company.com"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">Phone</label>
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="••••••••"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Confirm Password</label>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                placeholder="••••••••"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                required
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900">Role</label>
                                            {data && <select
                                                id="role"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                value={role}
                                                onChange={e => {
                                                    handleChangeRole(e.target.value)
                                                }}
                                            >
                                                {data.roles &&
                                                    data.roles.map((role: any, roleIndex: number) =>
                                                            <option key={roleIndex} value={role.id}>{role.label}</option>
                                                    )}
                                            </select>
                                            }
                                        </div>
                                        <button
                                            type="submit"
                                            className="mt-10 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        >
                                            Add
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {
                accountToView !== '' &&
                    <div className="fixed top-0 bottom-0 right-0 left-[3.8%] overflow-y-auto bg-white flex justify-center">
                        <div className="w-full p-10 pt-5">
                            <div className="flex items-center justify-between mb-10"> 
                                <h3 className="text-2xl font-bold">{accountToView} member access</h3>
                                <div className={`flex justify-end items-center block`}>
                                    <button type="button" onClick={toggleModal} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">+ Add members</button>
                                </div>
                            </div>
                            <h3 className="text-2xl font-bold mb-5">Members :</h3>
                            <div className="flex items-start">
                                <div className="w-2/3">
                                    <ul role="list">
                                        {data && accountUsersToShow.map((user: any, index: number) => (
                                            <li key={index} className="pb-5">
                                                <div className="flex items-center space-x-4">
                                                    <div className={`flex-shrink-0 flex justify-center items-center text-white w-10 h-10 rounded-lg bg-indigo-500`}>
                                                        {user.name.charAt(0).toUpperCase()}
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <p className="text-sm font-medium text-gray-900 truncate">
                                                            {user.name}
                                                        </p>
                                                        <p className="text-sm text-gray-500 truncate">
                                                            {user.email}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        {(data && data.role === "super_admin" && user.role.name === "super_admin") && 
                                                        <select   
                                                            disabled 
                                                            id="countries"                                                     
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        >
                                                            <option>{user.role.label}</option>
                                                        </select>
                                                        }
                                                        {(data && data.role === "super_admin" && user.role.name !== "super_admin") &&
                                                            <select
                                                                id="countries"
                                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                onChange={e => handleChange(user.id,e.target.value)}
                                                            >
                                                                <option value={user.role.id}>{user.role.label}</option>
                                                                {data.roles && data.roles.map((role: any, index: any) => (
                                                                    role.name !== user.role.name ? (
                                                                        <option key={index} value={role.id}>{role.label}</option>
                                                                    ) : null
                                                                ))}
                                                            </select>
                                                        }
                                                    </div>
                                                    <FontAwesomeIcon onClick={()=> deleteUserPopUp(user.id,user.name)} icon={faTrash} className='text-sm text-red-600 bg-gray-200 p-1 rounded' />
                                                    <button className="bg-blue-600 hover:bg-blue-800 px-2 py-1 rounded text-white" onClick={()=>{
                                                        setRestPasswordEmail(user.email)
                                                        setRestPasswordUser(user.name)
                                                        setResetPasswordPopup(true)
                                                    }}>Reset Password</button>
                                                </div>
                                            </li>
                                        ))} 
                                    </ul>
                                    <button type="button" onClick={e=>handleSave(e)} className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ${data.role !== "super_admin" && data.role !== "admin" && data.role !== "account_admin" && data.role !== "account_manager" ? "hidden" : "block"}`}>Save</button>
                                </div>
                                <div className="w-1/3 h-full ps-16 text-gray-500 font-normal text-sm">
                                    <p>A member access page restricts specific content or services to registered users only. Visitors must log in with their credentials to gain access. This ensures that sensitive or premium information is protected and only available to authorized users.</p>
                                    <p className="pt-5">It enhances security, provides personalized user experiences, and can offer exclusive benefits or content, encouraging user registration and engagement on the platform.</p>
                                </div> 
                            </div>
                        </div>
                    </div>
            }
            {toggleCreateAccount && (
                    <div
                        id="authentication-modal"
                        aria-hidden="true"
                        className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-y-auto bg-black bg-opacity-50"
                    >
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow">
                                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Add Account
                                    </h3>
                                    <button
                                        type="button"
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                                        onClick={()=>setToggleCreateAccount(false)}
                                    >
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close Account</span>
                                    </button>
                                </div>
                                {/* Account body */}
                                <div className="p-4 md:p-5">
                                    <form className="space-y-4" onSubmit={createAccount}>
                                        <div>
                                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Account Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="Account Name"
                                                required
                                                value={accountName}
                                                onChange={(e) => setAccountName(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="contactPerson" className="block mb-2 text-sm font-medium text-gray-900">Contact Person</label>
                                            <input
                                                type="text"
                                                name="contactPerson"
                                                id="contactPerson"
                                                placeholder="Contact Person"
                                                value={accountContactPerson}
                                                onChange={(e) => setAccountContactPerson(e.target.value)}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="name@company.com"
                                                required
                                                value={accountEmail}
                                                onChange={(e) => setAccountEmail(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">Contact Number</label>
                                            <input
                                                type="phone"
                                                name="phone"
                                                id="phone"
                                                placeholder="Contact Number"
                                                value={accountPhone}
                                                onChange={(e) => setAccountPhone(e.target.value)}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="subscription" className="block mb-2 text-sm font-medium text-gray-900">Subscription</label>
                                            {data && <select
                                                id="role"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                value={selectedSub}
                                                onChange={e => {
                                                    setSelectedSub(Number(e.target.value))
                                                }}
                                            >
                                                <option value='0'>Select a subscription plan</option>
                                                {subs &&
                                                    subs.map((list: any, index: number) =>
                                                            <option key={index} value={list.id}>{list.name}</option>
                                                    )}
                                            </select>
                                            }
                                        </div>
                                        <div>
                                            <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">Platform fee paid($)</label>
                                            <input
                                                type="number"
                                                name="amount"
                                                id="amount"
                                                placeholder="Platform fee paid"
                                                value={accountAmount}
                                                onChange={(e) => setAccountAmount(Number(e.target.value))}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                required
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="mt-10 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        >
                                            Add
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            )}
            {deleteConformPopup &&
                <div className='fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
                    <div className='w-1/3 bg-white border-2 p-10 bg-white' style={{borderRadius:'25px'}}>
                        <div className='flex items-center justify-center mb-5'>
                            <FontAwesomeIcon icon={faCircleExclamation} className='bg-red-100 border border-red-500 p-2 text-red-500 text-2xl rounded-full'/>
                        </div>
                        <h3 className='text-center mb-2 font-bold'>Are you Sure?</h3>
                        <p className='text-center text-gray-500'>This will delete the user "{currentDeleteName}" permanently. You cannot undo this action.</p>
                        <div className='flex justify-center items-center mt-2'>
                            <button className='me-3 bg-red-400 text-white px-5 hover:bg-red-600 rounded' onClick={e=>{
                                e.preventDefault()
                                deleteUser1()
                            }}>Delete</button>
                            <button className='bg-gray-500 text-white px-6 hover:bg-gray-600 rounded' onClick={e=>{
                                e.preventDefault()
                                setDeleteConformPopup(false)
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            }
            {
                resetPasswordPopup && 
                    <div className='fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
                        <div className='relative w-1/3 bg-white border-2 p-10 bg-white' style={{borderRadius:'25px'}}>
                            <div className='text-center'>
                                <p className="text-xl font-bold">Reset Password</p>
                                <p>User : "{restPasswordUser}"</p>
                                <input type="password" onChange={(e)=>setNewPassword(e.target.value)} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`mb-3 mt-5 shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`} placeholder="Password"/>
                                <input type="password" onChange={(e)=>setNewConfirmPassword(e.target.value)} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`} placeholder="Confirm Password"/>
                                <button className="bg-blue-600 hover:bg-blue-800 p-1 rounded text-white mt-2 w-full" onClick={submitNewPassword}>Submit</button>
                            </div>
                            <div className="absolute top-2 right-4">
                                <button onClick={()=>{
                                    setResetPasswordPopup(false)
                                    setRestPasswordEmail('')
                                    setRestPasswordUser('')
                                    setNewPassword('')
                                    setNewConfirmPassword('')
                                }}><FontAwesomeIcon className="text-red-500 text-xl" icon={faXmarkCircle} /></button>
                            </div>
                        </div>
                    </div>
            }
            {
                toggleAccountEditPopup &&
                    <div className='fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center border'>
                        <div className='relative w-1/3 bg-white border-2 p-10 bg-white' style={{borderRadius:'25px'}}>
                            <label>Account Name</label>
                            <input value={currectAccountEditRow.name} onChange={(e)=>{
                                setCurrectAccountEditRow((prevData:any) => ({
                                    ...prevData,
                                    name: e.target.value
                                }))
                            }} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`mb-5 mt-1 shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`}/> <br></br>
                            <label>Contact Person</label>
                            <input onChange={(e)=>{
                                setCurrectAccountEditRow((prevData:any) => ({
                                    ...prevData,
                                    contact_person: e.target.value
                                }))
                            }} value={currectAccountEditRow.contact_person} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`mb-5 mt-1 shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`}/>
                            <label>Expire Date</label>
                            <input onChange={(e)=>{
                                setCurrectAccountEditRow((prevData:any) => ({
                                    ...prevData,
                                    expiry: e.target.value
                                }))
                            }} value={currectAccountEditRow.expiry} style={{backgroundColor:'#F9FAFB',border:"0.5px solid #94C1FF"}} className={`mb-3 mt-1 shadow-sm text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-start`}/>
                            <button className="bg-blue-600 hover:bg-blue-800 p-1 rounded text-white mt-2 w-full" onClick={submitAccountChanges}>Submit</button>
                            <div className="absolute top-2 right-4">
                                <button onClick={()=>{
                                    setToggleAccountEditPopup(false)
                                    setCurrectAccountEditRow({})
                                }}><FontAwesomeIcon className="text-red-500 text-xl" icon={faXmarkCircle} /></button>
                            </div>
                        </div>
                    </div>
            }
            {
                accountStatusPopup &&
                    <div className='fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center'>
                        <div className='w-1/3 bg-white border-2 p-10 bg-white' style={{borderRadius:'25px'}}>
                            <div className='flex items-center justify-center mb-5'>
                                <FontAwesomeIcon icon={faCircleExclamation} className='bg-red-100 border border-red-500 p-2 text-red-500 text-2xl rounded-full'/>
                            </div>
                            <h3 className='text-center mb-2 font-bold'>Are you Sure?</h3>
                            <p className='text-center text-gray-500'>This will {updatedAccountStatus} the project "{currectAccountName}".</p>
                            <div className='flex justify-center items-center mt-2'>
                                <button className='me-3 bg-red-400 text-white px-5 hover:bg-red-600 rounded' onClick={e=>{
                                    e.preventDefault()
                                    UpdateAccountStatus()
                                }}>Update</button>
                                <button className='bg-gray-500 text-white px-6 hover:bg-gray-600 rounded' onClick={e=>{
                                    e.preventDefault()
                                    setAccountStatusPopup(false)
                                }}>Cancel</button>
                            </div>
                        </div>
                    </div>
            }
            {loading &&
                <div style={{position:'fixed',top:'0',bottom:'0',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div style={{background:'white',border:' 2px solid #0050C8'}} className="flex items-center rounded px-7 py-3">
                        <div className="spinner me-2"></div>
                        {
                            <p className="text-blue-700">Loading...</p>
                        }
                    </div>
                </div>
            }
        </div>
    );
}
export default Role;