import axios from 'axios';
import { Dispatch } from 'redux';

const api = axios.create({
  baseURL: 'https://api.xorostory.com',
  // baseURL: 'http://localhost:3000',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
}); 

const FileApi = axios.create({
  baseURL: 'https://api.xorostory.com',
  // baseURL: 'http://localhost:3000',
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  maxContentLength: 50 * 1024 * 1024,
});

const token = localStorage.getItem('token');
api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
FileApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export const loginCheck = async () => {
    const response = await api.get(`/api/admin/check`);
    return response.data;
};

export const logoutUser = async () => {
  const response = await api.get(`/api/admin/logout`);
  return response.data;
};

export const fetchUsers = async (page: Number = 1) => {
  const response = await api.get(`/api/admin/roles/${page}`);
  return response.data;
};

export const fetchProjectEmailStatus = async (code: String) => {
  const response = await api.get(`/api/admin/fetchProjectEmailStatus/${code}`);
  return response.data;
};

export const lastIDOfProject = async () => {
  const response = await api.get(`/api/admin/lastIDOfProject`);
  return response.data;
};

export const fetchUsersByAccount = async (account_id: Number) => {
  const response = await api.get(`/api/admin/usersByAccount/${account_id}`);
  return response.data;
};

export const fetchLogin = async (email: string, password: string) => {
    const response = await api.post('/api/admin/login', {
      "auth": {
        email,
        password,
      }
    });
    return response.data;
};

export const getSubscriptions1 = async () => {
  const response = await api.get(`/api/admin/getSubscriptions`);
  return response.data;
};

export const get3dRepo = async () => {
  const response = await api.get(`/api/admin/get3dRepo`);
  return response.data;
};

export const checkRoleUpdation = async (id: any) => {
  const response = await api.post('/api/admin/checkRoleUpdation', {
    "checkRoleUpdation": {
      id:Number(id),
    }
  });
  return response.data;
};

export const deleteUser = async (id: any) => {
  const response = await api.post('/api/admin/deleteUser', {
    "deleteUser": {
      id:Number(id),
    }
  });
  return response.data;
};

export const deleteProjects = async (id: any) => {
  const response = await api.post('/api/admin/deleteProject', {
    "deleteProject": {
      id:Number(id),
    }
  });
  return response.data;
};

export const updateRoleUpdation = async (id: any) => {
  const response = await api.post('/api/admin/updateRoleUpdation', {
    "updateRoleUpdation": {
      id:Number(id),
    }
  });
  return response.data;
};

export const signUp = async (organization: string, name: string, email: string, phone: string, password: string, confirmPassword: string, role: Number = 1) => {
  const response = await api.post('/api/admin/signup', {
    "signup": {
      "account_id": Number(organization),
      "contact_person": name,
      "email": email,
      "phone": phone,
      "is_active": false,
      "users_attributes": [{
        "name": name,
        "email": email,
        "phone": phone,
        "password": password,
        "password_confirmation": confirmPassword,
        "role_id": role,
        "is_active": true,
      }]
    }
  });
  return response;
};

export const resetPassword = async (email: any,password: string) => {
  const response = await api.post('/api/admin/resetPassword', {
    "resetPassword": {
      email,
      password
    }
  });
  return response.data;
};

export const updateMemberRole = async (obj:any) => {
  const response = await api.post(`/api/admin/updateMemberRole`, {
    'updatedUsers' : obj
  });
  return response;
};

export const submitAccountChangesApi = async (obj:any) => {
  const response = await api.post(`/api/admin/updateAccount`, {
    'updatedAccount' : obj
  });
  return response;
};

export const updateAccountStatusApi = async (id:Number,status:string) => {
  const response = await api.post(`/api/admin/updateAccountStatus`, {
    'updateAccountStatus': {'id' : id,
                          'status' : status}
  });
  return response;
};

export const sendOtpApi = async (email:string) => {
  const response = await api.post(`/api/admin/otpMail`, {
    'mailCred': {'to_email' : email}
  });
  return response;
};

export const deleteAllEvents = async (projectName:any,platform:string) => {
  const response = await api.post(`/api/admin/deleteAllEvents`, {
    "deleteAllEvents": {
      'projectName' : projectName,
      'platform' : platform
    }
  });
  return response;
};

export const deleteSingleEvent = async (projectName:any,customerEmail:any,platform:string) => {
  const response = await api.post(`/api/admin/deleteSingleEvent`, {
    "deleteSingleEvent": {
      'projectName' : projectName,
      'customerEmail' : customerEmail,
      'platform' : platform
    }
  });
  return response;
};

export const updateProjectStatus1 = async (status:any,projectCode:any) => {
  const response = await api.post(`/api/admin/updateProjectStatus`, {
    "updateProjectStatus": {
      'status' : status,
      'projectCode' : projectCode,
    }
  });
  return response;
};

export const userUpdate = async (roleId: Number, UserId: Number) => {
  const response = await api.post(`/api/admin/roles/${UserId}`, {
    "user": {
      "role_id": roleId,
    }
  });
  return response;
};

export const fetchDashboardDatas = async (page: Number = 1) => {
  const response = await api.get(`/api/admin/dashboard/${page}`);
  return response.data;
};

export const fetchDashboardProductDatas = async (projuct: String = "", page: Number = 1 , platform:string) => {
    const response = await api.get(`/api/admin/dashboard_project?project=${projuct}&page=${page}&platform=${platform}`);
    return response;
};

export const fetchDashboardProductDatasByEmail = async (project:string,email:string) => {
  const response = await api.get(`/api/admin/dashboard_project_byEmail?project=${project}&email=${email}`);
  return response;
};

export const projectTableColumnFetch = async (input:any) => {
  try {
    const response = await api.post('/api/admin/projectColumnDetails', {
      "text": input
    });
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const storiesTableColumnFetch = async (input:any) => {
  try {
    const response = await api.post('/api/admin/storyColumnDetails', {
      "text": input
    });
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const featuresTableColumnFetch = async (input:any) => {
  try {
    const response = await api.post('/api/admin/featureColumnDetails', {
      "text": input
    });
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const audioEnhancer = async (input:any) => {
  try {
    const response = await api.post('/api/admin/audio/enhancer', {
      "text": input
    }
  );
    
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const audioConvert = async (input:any) => {
  try {
    const response = await api.post('/api/admin/audio/converter', {
      "text": input
    }
    , { responseType: 'blob' }
  );
    
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const audioUpload = async (input:any,name:string,page:string,projectName:any) => {
  try {
      const response = await api.post('/api/admin/audio/uploadAudio', {
        "text": input,
        "name": name,
        "page": page,
        "projectName": projectName
      });
      return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const fileUpload = async (input:any,page:string,projectName:any) => {
  try {
    input.append('page', page);
    input.append('projectName', projectName.replace(/\s+/g, ''))
    const response = await FileApi.post('/api/admin/file/fileUpload', input);
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const assestUpload = async (input:any,type:string,projectName:string) => {
  try {
    input.append('type', type);
    input.append('projectName', projectName.replace(/\s+/g, ''))
    const response = await FileApi.post('/api/admin/assestUpload', input);
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const createAccount1 = async (name:string,contact_person:string,email:string,phone:any,subscription:number,accountAmount:any) => {
  try {
    const response = await api.post('/api/admin/createAccount', {
      "name": name,
      "contact_person": contact_person,
      "email": email,
      "phone": phone,
      "subscription": subscription,
      "amount": accountAmount
    });
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const createProject = async (inputs:any,batchRunner:boolean,edit:boolean,code:any) => {
  try {
    const response = await api.post('/api/admin/configurator/projects', {
      "project": inputs,
      "batchRunner": batchRunner,
      "edit": edit,
      "code": code
    });
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const createStroies = async (inputs:any, code:any) => {
  try {
    const response = await api.post(`/api/admin/configurator/stories/${code}`, {
      "stories": inputs
    });
    
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const createFeatures = async (inputs:any, code:any) => {
  try {
    const response = await api.post(`/api/admin/configurator/features/${code}`, {
      "features": inputs
    });
    
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const createObjects = async (inputs:any, code:any) => {
  try {
    const response = await api.post(`/api/admin/configurator/objects/${code}`, {
      "objects": inputs
    });
    
    return response;
  } catch (error) {
    console.error('Error create data', error);
    throw error;
  }
};

export const fetchConfiguratorProjectsDatas = async (page: Number = 1) => {
  
  const response = await api.get(`/api/admin/configurator/projects/${page}`);
  return response.data;
};

export const fetchConfiguratorProjectData = async (code: String) => {
  const response = await api.get(`/api/admin/configurator/project/${code}`);
  return response;
};

export const fetchConfiguratorStoryData = async (code: String) => {
  const response = await api.get(`/api/admin/configurator/stories/${code}`);
  return response;
};

export const deleteStoryData = async (code: any,id: any) => {
  const response = await api.get(`/api/admin/configurator/stories/delete/${code}/${id}`);
  return response;
};

export const fetchConfiguratorFeaturesData = async (code: string) => {
  const response = await api.get(`/api/admin/configurator/features/${code}`);
  return response;
}

export const deleteFeaturesData = async (code: any,id: any) => {
  const response = await api.get(`/api/admin/configurator/features/delete/${code}/${id}`);
  return response;
};

export const fetchConfiguratorObjectssData = async (code: string) => {
  const response = await api.get(`/api/admin/configurator/objects/${code}`);
  return response;
}

export const deleteConfiguratorProjectCustomField = async (id: any) => {
  const response = await api.delete(`/api/admin/configurator/project/custom_field/${id}`);
  return response;
}

export const deleteConfiguratorStoryCustomField = async (id: any) => {
  const response = await api.delete(`/api/admin/configurator/story/custom_field/${id}`);
  return response;
}

export const deleteConfiguratorFeatureCustomField = async (id: any) => {
  const response = await api.delete(`/api/admin/configurator/feature/custom_field/${id}`);
  return response;
}

export const deleteConfiguratorObjectCustomField = async (id: any) => {
  const response = await api.delete(`/api/admin/configurator/object/custom_field/${id}`);
  return response;
}

export const fetchOptionsAccount = async () => {
  const response = await api.get('/api/admin/configurator/options/accounts');
  return response;
}