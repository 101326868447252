import logo from "../assects/img/XORO-White-1080 (4).png"

function Nav(){
    return(
        <div className="h-[60px] w-full bg-gray-800 rounded flex items-center justify-between">
            <div className="w-30% h-full p-4">
                <img src={logo}/>
            </div>
            <div className="text-white pe-4"><p>{localStorage.getItem('expire_status') === 'false' ? `Your licence expires in ${localStorage.getItem('remaining_days')} days` : 'Licence Expired' }</p></div>
        </div>
    )
}

export default Nav;