import React, { ReactNode } from "react";
import SideBar from "../components/SideBar";
import Alert from "../components/Alert";

interface SidebarLayoutProps {
    children: ReactNode;
    isError: boolean;
    error: string | null;
    setIsError: any;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children, isError, error, setIsError }) => {
    const handleDismiss = (alertId: string) => {
        const alertElement = document.getElementById(alertId);
        if (alertElement) {
            setIsError(false);
            alertElement.style.display = 'none';
        }
    };
    
    return (
        <div className="w-full h-full flex">
        {isError &&
            <Alert
                id="alert-2"
                color="red"
                iconColor="text-red-800 dark:text-red-400"
                textColor="text-red-800 dark:text-red-400"
                bgColor="bg-red-50 dark:bg-gray-800"
                onDismiss={() => handleDismiss('alert-2')}
            >
                {error === null ? "Some error happen" : error}
            </Alert>}
            <aside className="w-auto h-[100vh] border-gray-700" aria-label="Sidebar">
                <SideBar />
            </aside>
            <div className="w-full h-[100vh]">
                {children}
            </div>
        </div>
    );
}
export default SidebarLayout;